/* fashion */
import icon1_fashion from '@/assets/img/fashion/icon1.png';
import icon2_fashion from '@/assets/img/fashion/icon2.png';
import icon3_fashion from '@/assets/img/fashion/icon3.png';
import block1_fashion from '@/assets/img/fashion/block1.png';
import block2_fashion from '@/assets/img/fashion/block2.png';
import dashboardImg_fashion from '@/assets/img/fashion/dashboardImg.png';
import dashbordBg_fashion from '@/assets/img/fashion/dashbordBg.png';
/* beauty */
import block1_beauty from '@/assets/img/beauty/beautyOne.png';
import icon1_beauty from '@/assets/img/beauty/icon1.png';
import icon2_beauty from '@/assets/img/beauty/icon2.png';
import icon3_beauty from '@/assets/img/beauty/icon3.png';
import icon4_beauty from '@/assets/img/beauty/icon4.png';
import dashbordBg_beauty from '@/assets/img/beauty/dashbordBg.png';
import dashboardImg_beauty from '@/assets/img/beauty/dashbordImg.png';
/* fabric */
import dashboardImg_fabric from '@/assets/img/fabric/dashbordImg.png';
/* toys */
import icon3_toys from '@/assets/img/toys/icon3_toys.png';
import icon4_toys from '@/assets/img/toys/icon4_toys.png';
import block1_toys from '@/assets/img/toys/block1_toys.png';
import dashboardBg_toys from '@/assets/img/toys/dashbordBg.png';
import dashboardImg_toys from '@/assets/img/toys/dashbordImg.png';
/* outdoor */
import icon2_outdoor from '@/assets/img/outdoor/icon2_outdoor.png';
import icon3_outdoor from '@/assets/img/outdoor/icon3_outdoor.png';
import block1_outdoor from '@/assets/img/outdoor/block1_outdoor.png';
import dashboardBg_outdoor from '@/assets/img/outdoor/dashboardBg.png';
import dashboardImg_outdoor from '@/assets/img/outdoor/dashboardImg.png';
const DATA = {
  fashion: {
    banner: 'http://cowork-storage-public-cdn.lx.netease.com/common/2022/12/15/0776eb841e7a46cdb5b0f492372873ea.png',
    bannerColor: '#fff',
    bannerTitle: (
      <p className="station-banner-title font-bold text-xxxl md:text-xl1">
        <span style={{ color: '#5E79FF' }}>Cloud factory </span> of <br className="invisible md:visible" />
        <span style={{ color: '#66DCE6' }}> fashion </span> with technology <br className="invisible md:visible" /> to integrate the global{' '}
        <br className="invisible md:visible" /> supply chain
      </p>
    ),
    bannerSubTitle: <p className=" mt-6 text-xl text-black-c md:text-sm">{'Selected Supply Chain for Global Brands'}</p>,
    bannerDesc: (
      <p className="g-banner-desc mt-6 text-base text-black-c md:text-sm">
        {'Using advanced systems to manage the supply chain and provide customers with more convenient and lower price solutions'}
      </p>
    ),
    iconsBg: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/30/7ea579a6b9de447daf1ac2bbccfd0824.png',
    icons: [
      { icon: icon1_fashion, text: 'Over 1000+ suppliers' },
      { icon: icon2_fashion, text: 'Low MOQ' },
      { icon: icon3_fashion, text: 'Manufacturing bases all over the world' },
    ],
    block1: {
      img: block1_fashion,
      title: 'One-stop Service',
      content: (
        <ul className="text-white opacity-70 g-home-block-desc mt-14 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>No matter how many categories you have, we can meet the needs of customers and provide selected suppliers</li>
          <li>Instead of spending time on selecting suppliers, customers can better focus on products, orders and sales</li>
          <li>Cooperative factories have undergone strict audits and obtained a series of certifications, in line with our sustainable concepts</li>
        </ul>
      ),
    },
    block2: {
      img: block2_fashion,
      title: 'Supply Chain Advantage',
      content: (
        <ul className="text-black-f g-home-block-desc mt-16 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>We have multiple manufacturing bases around the world,Such as Cambodia, Vietnam, Guangzhou.</li>
          <li>Each manufacturing base has its own production advantages We can obtain price advantages from different production bases</li>
          <li>Low MOQ.</li>
        </ul>
      ),
    },
    dashbord: {
      bg: dashbordBg_fashion,
      img: dashboardImg_fashion,
      title: (
        <p className="station-dashbord-title text-white  text-xxl md:text-base font-bold station-block-title station-block-titleFFDAA5">
          Transparent, Traceable and Systematic
        </p>
      ),
      content: (
        <ul className="station-dashbord-text text-white  text-base">
          <li>Real-time tracking of production progress to reduce production cycle</li>
          <li>The order information is transparent and traceable, which is convenient for customers to manage orders </li>
          <li>The customized system runs through the whole process, saving time and manpower for customer follow-up</li>
        </ul>
      ),
    },
    aboutBannerTitle: 'We provide sustainable fashion products to create a better living environment for our customers',
    aboutBannerDesc:
      'Our own organization has committed to ESG initiatives that combine our vision with action toward a more sustainable future. We are committed to making a positive impact on the health of our planet.',
    neteaseDoorText: `NetEase, NASDAQ-listed since 2000, is one of the largest Chinese tech conglomerates and the leader in global ESG initiatives.
    Spearheaded by Yanxuan, NetEase's consumer good brand, we have been reorganizing and restructuring the supply chain of the fashion
    industry towards a greener future by switching to recycled material and carbon-neutral manufacturing. 
    As the subsidiary running all supply chains, EaseSupply is committed to making fashion industry more sustainable.`,
    categoryToLowerCase: 'Fashion',
  },
  beauty: {
    banner: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/27/31654491d9ca44df94f509df3ef296d0.png',
    bannerColor: '#f4e2d9',
    bannerTitle: (
      <p className="station-banner-title font-bold text-xxxl md:text-xl1">
        Sourcing, Building, <br className="invisible md:visible" />
        and Delivering Excellence
      </p>
    ),
    bannerSubTitle: <p className=" mt-6 text-xl text-black-c md:text-sm">{'Selected Supply Chain for Global Beauty Brands'}</p>,
    bannerDesc: (
      <p className="g-banner-desc mt-6 text-base text-black-c md:text-sm">
        {
          'If you have a product vision, then we are the partner you need to bring it to life. Don’t spend time researching overseas factories, investigating pricing, or traveling thousands of miles to see manufacturing facilities – let us do the heavy lifting and ensure a good deal and a quality product every time.'
        }
      </p>
    ),
    iconsBg: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/30/ec719d3561f746bb848acbd5a7ceb90e.png',
    icons: [
      { icon: icon1_beauty, text: 'Over 1000+ suppliers' },
      { icon: icon2_beauty, text: 'Innovative design' },
      { icon: icon3_beauty, text: 'Professional SCS' },
      { icon: icon4_beauty, text: 'Powerful data analysis ability' },
    ],
    block1: {
      img: block1_beauty,
      title: 'One-stop Service',
      content: (
        <ul className="text-white opacity-70 g-home-block-desc mt-14 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>No matter how many categories you have, we can meet the needs of customers and provide selected suppliers</li>
          <li>Instead of spending time on selecting suppliers, customers can better focus on products, orders and sales</li>
          <li>Cooperative factories have undergone strict audits and obtained a series of certifications, in line with our sustainable concepts</li>
        </ul>
      ),
    },
    block2: {
      img: block2_fashion,
      title: 'Supply Chain Advantage',
      content: (
        <ul className="text-black-f g-home-block-desc mt-16 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>We have multiple manufacturing bases around the world,Such as Cambodia, Vietnam, Guangzhou.</li>
          <li>Each manufacturing base has its own production advantages.</li>
          <li>We can obtain price advantages from different production bases.</li>
        </ul>
      ),
    },
    dashbord: {
      bg: dashbordBg_beauty,
      img: dashboardImg_beauty,
      title: (
        <p className="station-dashbord-title text-black-c md:text-white  text-xxl md:text-base font-bold station-block-title station-block-title333333">
          Transparent, Traceable and Systematic
        </p>
      ),
      content: (
        <ul className="station-dashbord-text text-black-c md:text-white  text-base">
          <li>Real-time tracking of production progress to reduce production cycle</li>
          <li>The order information is transparent and traceable, which is convenient for customers to manage orders </li>
          <li>The customized system runs through the whole process, saving time and manpower for customer follow-up</li>
        </ul>
      ),
    },
    aboutBannerTitle: 'We provide eco-friendly beauty products to create a better living environment for our customers',
    aboutBannerDesc:
      'Our own organization has committed to ESG initiatives that combine our vision with action toward a more sustainable future. We are committed to making a positive impact on the health of our planet.',
    neteaseDoorText: `NetEase, NASDAQ-listed since 2000, is one of the largest Chinese tech conglomerates and the leader in global ESG initiatives.
    Spearheaded by Yanxuan, NetEase's consumer good brand, we have been reorganizing and restructuring the supply chain of the beauty industry towards a greener future by switching to recycled material and carbon-neutral manufacturing.
    As the subsidiary running all supply chains, EaseSupply develops and manufactures cosmetics for the most well-known brands in the industry.`,
    categoryToLowerCase: 'Beauty',
  },
  fabric: {
    banner: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/01/03/d27ee71629de4030b9eab94dad1cad9a.png',
    bannerColor: '#fff',
    bannerTitle: (
      <p className="station-banner-title font-bold text-xxxl md:text-xl1">
        <span style={{ color: '#5E79FF' }}>Cloud factory </span> of <span style={{ color: '#66DCE6' }}> fabric </span> with
        <br className="invisible md:visible" />
        technology to integrate the
        <br className="invisible md:visible" /> global supply chain
      </p>
    ),
    bannerSubTitle: <p className=" mt-6 text-xl text-black-c md:text-sm">{'Selected Supply Chain for Global Fabric Brands'}</p>,
    bannerDesc: (
      <p className="g-banner-desc mt-6 text-base text-black-c md:text-sm">
        {'Using advanced systems to manage the supply chain and provide customers with more convenient and lower price solutions'}
      </p>
    ),
    iconsBg: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/30/ec719d3561f746bb848acbd5a7ceb90e.png',
    icons: [
      { icon: icon1_fashion, text: 'Over 1000+ suppliers' },
      { icon: icon2_fashion, text: 'Low MOQ' },
      { icon: icon3_fashion, text: 'Manufacturing bases all over the world' },
    ],
    block1: {
      img: block1_fashion,
      title: 'One-stop Service',
      content: (
        <ul className="text-white opacity-70 g-home-block-desc mt-14 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>No matter how many categories you have, we can meet the needs of customers and provide selected suppliers</li>
          <li>Instead of spending time on selecting suppliers, customers can better focus on products, orders and sales</li>
          <li>Cooperative factories have undergone strict audits and obtained a series of certifications, in line with our supply chain standards</li>
        </ul>
      ),
    },
    block2: {
      img: block2_fashion,
      title: 'Supply Chain Advantage',
      content: (
        <ul className="text-black-f g-home-block-desc mt-16 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>We have multiple manufacturing bases around the world,Such as Cambodia, Vietnam, mainland of china.</li>
          <li>Each manufacturing base has its own production advantages We can obtain price advantages from different production bases</li>
          <li>Low MOQ</li>
        </ul>
      ),
    },
    dashbord: {
      bg: dashbordBg_fashion,
      img: dashboardImg_fabric,
      title: (
        <p className="station-dashbord-title text-white  text-xxl md:text-base font-bold station-block-title station-block-titleFFDAA5">
          Transparent, Traceable and Systematic
        </p>
      ),
      content: (
        <ul className="station-dashbord-text text-white  text-base">
          <li>Real-time tracking of production progress to reduce production cycle</li>
          <li>The order information is transparent and traceable, which is convenient for customers to manage orders </li>
          <li>The customized system runs through the whole process, saving time and manpower for customer follow-up</li>
        </ul>
      ),
    },
    aboutBannerTitle: 'We provide sustainable fabric products to create a better living environment for our customers',
    aboutBannerDesc:
      'Our own organization has committed to ESG initiatives that combine our vision with action toward a more sustainable future. We are committed to making a positive impact on the health of our planet.',
    neteaseDoorText: `NetEase, NASDAQ-listed since 2000, is one of the largest Chinese tech conglomerates and the leader in global ESG initiatives.
    Spearheaded by Yanxuan, NetEase's consumer good brand, we have been reorganizing and restructuring the supply chain of the fabric industry towards a greener future by switching to recycled material and carbon-neutral manufacturing.
    As the subsidiary running all supply chains, EaseSupply is committed to making fabric industry more sustainable.`,
    categoryToLowerCase: 'Fabric',
  },
  /* 潮玩 */
  toys: {
    banner: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/02/02/44501a4d11c047eb8a19d96a0a3d2df7.png',
    bannerColor: '#fff',
    bannerTitle: (
      <p
        className=" font-bold text-xxxl md:text-xl1"
        style={{
          visibility: 'hidden',
          textAlign: 'center',
          color: '#fff',
          textShadow: '#000 4px 2px 6px, #000 2px 4px 6px, #000 -4px 2px 6px, #000 2px -4px 6px',
        }}
      >
        Sourcing, Building, <br />
        and Delivering Excellence
      </p>
    ),
    bannerSubTitle: (
      <p className=" mt-8 text-xl text-black-c md:text-sm" style={{ textAlign: 'center' }}>
        {'Selected Supply Chain for Global Buyers'}
      </p>
    ),
    bannerDesc: (
      <div className="g-banner-desc-toys">
        <p className="g-banner-desc mt-6 text-base text-black-c md:text-sm" style={{ textAlign: 'center' }}>
          {
            'If you have a product vision, then we are the partner you need to bring it to life. Don’t spend time researching overseas factories, investigating pricing, or traveling thousands of miles to see manufacturing facilities – let us do the heavy lifting and ensure a good deal and a quality product every time.'
          }
        </p>
      </div>
    ),
    iconsBg: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/01/18/fc8475b24e7d4c4a97c3c4827118a769.png',
    icons: [
      { icon: icon1_fashion, text: '1000+ Reliable Suppliers' },
      { icon: icon2_fashion, text: '7+ Days Delivery in Low MOQ' },
      { icon: icon3_toys, text: 'Latest Popular Brands & Products' },
      { icon: icon4_toys, text: 'Customization Available' },
    ],
    block1: {
      img: block1_toys,
      title: 'One-stop Sourcing',
      content: (
        <ul className="text-white opacity-70 g-home-block-desc mt-14 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>Simplify purchasing: Find a wide range of relevant supplies in one place.</li>
          <li>Production Monitoring: Provides ongoing tracking and reporting of production progress.</li>
          <li>
            Inspection: Helps to evaluate suppliers through different parameters including production capacities, factory facilities, workflow, QA,
            social responsibility, environmental standards compliance, etc.
          </li>
          <li>Logistics: Ship your item from door to door with our economical solution.</li>
        </ul>
      ),
    },
    block2: {
      img: block2_fashion,
      title: 'Reliable Manufacture Experts',
      content: (
        <ul className="text-black-f g-home-block-desc mt-16 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>Multiple manufacturing bases throughout China mainland, such as Guangzhou, Shantou, Ningbo, etc.</li>
          <li>Each manufacturing base is experienced in specific category and has its own production advantages.</li>
          <li>Manufacturers conform to relevant audits to meet your region's import requirements.</li>
          <li>Product certificates can be issued to products to meet your market's on-shelf requirements.</li>
        </ul>
      ),
    },
    factory1: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/01/05/109e76674264418db1918f7d0db8148f.jpg',
    factory1text: 'Environment',
    factory2: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/01/05/9be964d72ac74fcbb40e7226b459120a.jpg',
    factory2text: 'Warehouse',
    factory3: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/01/05/bc82a607e72345cd8d75ba1f633b3dd5.jpg',
    factory3text: 'Machine',
    dashbord: {
      bg: dashboardBg_toys,
      img: dashboardImg_toys,
      title: (
        <p className="station-dashbord-title text-black-c  md:text-white text-xxl md:text-base font-bold station-block-title station-block-title333333">
          Transparent, Traceable and Systematic
        </p>
      ),
      content: (
        <ul className="station-dashbord-text text-black-c md:text-white  text-base">
          <li>Real-time tracking of production progress to reduce production cycle</li>
          <li>The order information is transparent and traceable, which is convenient for customers to manage orders </li>
          <li>The customized system runs through the whole process, saving time and manpower for customer follow-up</li>
        </ul>
      ),
    },
    aboutBannerTitle: 'We provide sustainable toys products to create a better living environment for our customers',
    aboutBannerDesc:
      'Our own organization has committed to ESG initiatives that combine our vision with action toward a more sustainable future. We are committed to making a positive impact on the health of our planet.',
    neteaseDoorText: `NetEase, NASDAQ-listed since 2000, is one of the largest Chinese tech conglomerates and the leader in global ESG initiatives.
    Spearheaded by Yanxuan, NetEase's consumer good brand, we have been reorganizing and restructuring the supply chain of the fabric industry towards a greener future by switching to recycled material and carbon-neutral manufacturing.
    As the subsidiary running all supply chains, EaseSupply is committed to provide a easy and reliable toys supplier chain solutions to overseas merchandisers.`,
    categoryToLowerCase: 'Toys',
    ourMission: (
      <div className="station-about-our-left md:mx-auto">
        <p className="text-xxxl font-bold md:text-xxl">Our Mission</p>
        <p className="text-base mt-8 md:mt-1">
          As an industry leader, EaseSupply's mission is to activate and inspire this creative and sustainable transition of the Supply Chain world.
        </p>
        <p className="text-base">
          While starting with our ow n actions, we also help our clients, B2C brands and our supplier in their evolution. We inspire their
          creativity(new products, new designs based on sustainable approaches) and empower their success.
        </p>
      </div>
    ),
  },
  /* 户外 */
  outdoor: {
    banner: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/27/fb6a1c467d404a39bfa9d314ee699965.png',
    bannerColor: '#fff',
    bannerTitle: (
      <p className="station-banner-title font-bold text-xxxl md:text-xl1">
        Sourcing, Building, <br />
        and Delivering Excellence
      </p>
    ),
    bannerSubTitle: <p className=" mt-6 text-xl text-black-c md:text-sm">{'Selected Supply Chain for Global Buyers'}</p>,
    bannerDesc: (
      <p className="g-banner-desc mt-6 text-base text-black-c md:text-sm">
        {
          'If you have a product vision, then we are the partner you need to bring it to life. Don’t spend time researching overseas factories, investigating pricing, or traveling thousands of miles to see manufacturing facilities – let us do the heavy lifting and ensure a good deal and a quality product every time.'
        }
      </p>
    ),
    iconsBg: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/30/ec719d3561f746bb848acbd5a7ceb90e.png',
    icons: [
      { icon: icon2_fashion, text: 'High Quality, Low Price' },
      { icon: icon2_outdoor, text: 'Short Lead Time' },
      { icon: icon3_outdoor, text: 'Full service' },
    ],
    block1: {
      img: block1_outdoor,
      title: 'One-stop Service',
      content: (
        <ul className="text-white opacity-70 g-home-block-desc mt-14 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>No matter how many categories you have, we can meet the needs of customers and provide selected suppliers</li>
          <li>Instead of spending time on selecting suppliers, customers can better focus on products, orders and sales</li>
          <li>Cooperative factories have undergone strict audits and obtained a series of certifications, in line with our sustainable concepts</li>
        </ul>
      ),
    },
    block2: {
      img: block2_fashion,
      title: 'Supply Chain Advantage',
      content: (
        <ul className="text-black-f g-home-block-desc mt-16 md:w-full text-base md:text-ssm md:text-left list-disc">
          <li>We have multiple manufacturing bases around the world,Such as Cambodia, Vietnam, Guangzhou.</li>
          <li>Each manufacturing base has its own production advantages.</li>
          <li>We can obtain price advantages from different production bases.</li>
        </ul>
      ),
    },
    factory1: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/27/5c9edc21a4724d9682f6191a27e3f85b.png',
    factory2: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/28/fce6a168977f449e9a7573f7415f13df.png',
    factory3: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/27/e09232e78dd24947b430e7f3a841b64e.png',
    dashbord: {
      bg: dashboardBg_outdoor,
      img: dashboardImg_outdoor,
      title: (
        <p className="station-dashbord-title text-black-c md:text-white  text-xxl md:text-base font-bold station-block-title station-block-title333333">
          Transparent, Traceable and Systematic
        </p>
      ),
      content: (
        <ul className="station-dashbord-text text-black-c md:text-white  text-base">
          <li>Real-time tracking of production progress to reduce production cycle</li>
          <li>The order information is transparent and traceable, which is convenient for customers to manage orders </li>
          <li>The customized system runs through the whole process, saving time and manpower for customer follow-up</li>
        </ul>
      ),
    },
    aboutBannerTitle: 'We provide sustainable  products to create a better living environment for our customers',
    aboutBannerDesc:
      'Our own organization has committed to ESG initiatives that combine our vision with action toward a more sustainable future. We are committed to making a positive impact on the health of our planet.',
    neteaseDoorText: `NetEase, NASDAQ-listed since 2000, is one of the largest Chinese tech conglomerates and the leader in global ESG initiatives.
    Spearheaded by Yanxuan, NetEase's consumer good brand, we have been reorganizing and restructuring the supply chain of the outdoor industry towards a greener future by switching to recycled material and carbon-neutral manufacturing.
    As the subsidiary running all supply chains, EaseSupply is committed to provide a easy and reliable outdoor supplier chain solutions to overseas merchandisers.`,
    categoryToLowerCase: 'Outdoor',
  },
};
export default DATA;
