import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setVisble, setHref } from '@/store/slices/modalFormSlice';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Head from '../components/Head';
import Foot from '../components/Foot';
import '../assets/css/home.less';
import ModalForm from '@/components/ModalForm';
import icon1 from '../assets/img/fashion/icon1.png';
import icon2 from '../assets/img/fashion/icon2.png';
import icon3 from '../assets/img/fashion/icon3.png';
import block1 from '../assets/img/fashion/block1.png';
import block2 from '../assets/img/fashion/block2.png';
import factoryTitle from '../assets/img/fashion/factoryTitle.png';
import factory1 from '../assets/img/fashion/factory1.png';
import factory2 from '../assets/img/fashion/factory2.png';
import factory3 from '../assets/img/fashion/factory3.png';
import dashbordBg from '../assets/img/fashion/dashbordBg.png';
import dashboardImg from '../assets/img/fashion/dashboardImg.png';
import HomeMobileSwiper from '@/components/HomeMobileSwiper';
import HomeBuyersSwiper from '@/components/HomeBuyersSwiper';
import HomePcSwiper from '@/components/HomePcSwiper';
import { swiperBuyers, swiperProducts } from './const';

import aircraft from '../assets/img/home/aircraft.png';
const imgAlt = 'NetEase';

function Home() {
  const dispatch = useDispatch();
  const showModalForm = () => {
    dispatch(setVisble(true));
    dispatch(setHref(window.location.host));
  };
  const goToAboutUs = () => {
    // if (window.location.host !== 'factory.163.com') {
    //   window.location.href = '//factory.163.com/';
    // }
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('#') + 1);
    document.getElementById(id)?.scrollIntoView(true);
  };
  useEffect(() => {
    goToAboutUs();
    window.gtag('event', 'page_view_home', { send_to: 'G-WRPTRE4PB8' });
  }, []);

  return (
    <div className="w-full g-min-w">
      <ModalForm />
      <Head />
      {/* <MHead /> */}
      <div className="station-banner-box flex justify-center items-center">
        <div
          className="g-banner w-full bg-cover bg-white md:text-center my-auto md:h-auto station-banner"
          style={{ backgroundImage: `url(https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/17/584702ac15324e97997d4f27b48d8669.png` }}
        >
          <div className="g-content mx-auto text-black-c md:p-6 md:pt-10">
            <p className="station-banner-title font-bold text-xxxl md:text-xl1">
              <span style={{ color: '#373B52' }}>
                Your One-Stop Sourcing <br className="invisible md:visible" /> Platform
              </span>
            </p>
            <p className=" mt-6 text-xl text-black-c md:text-sm">{'Sourcing, Building, and Delivering Excellence'}</p>

            <p className="g-banner-desc mt-6 text-base text-black-c md:text-sm">
              {
                "Leave the sourcing to us and rest assured that you're in good hands with us. Our team of sourcing experts is meticulously screened, interviewed, and trained to meet our unwavering standards of excellence. You can trust that every step of the sourcing process will be handled with the utmost care and attention to detail, so you can focus on bringing your product vision to life. Say goodbye to the complexities of overseas manufacturing and partner with EaseSupply for a stress-free sourcing experience"
              }
            </p>
            <div style={{ display: 'flex' }}>
              <span className="g-btn mt-22px md:mx-auto md:mb-4 g-btn-dark" onClick={showModalForm}>
                Schedule a meeting
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="station-advantage  bg-white-b flex items-center justify-center md:relative md:h-auto">
        <div className="g-content flex flex-col items-center justify-center">
          <LazyLoadImage
            className="station-advantage-bg md:hidden"
            src={'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/30/7ea579a6b9de447daf1ac2bbccfd0824.png'}
            alt=""
          />
          <div className="station-advantage-card-wrap w-full flex justify-between md:flex-col">
            {[
              { icon: icon1, text: 'Over 1000+ suppliers' },
              { icon: icon2, text: 'On-site expertise for sourcing' },
              { icon: icon3, text: 'Manufacturing bases all over the world' },
            ].map((item, index) => (
              <div className="station-advantage-card mx-auto mr-6 md:mr-auto" key={index}>
                <LazyLoadImage src={item.icon} alt="" />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="g-home-block g-station-block  bg-blue-dark  ">
        <div className="g-content flex justify-between md:flex-col">
          <LazyLoadImage className="station-block-img" src={block1} alt="" style={{ wdith: '500px', height: '333px' }} />
          <div className="flex flex-col justify-center station-block-text">
            <p className="text-white text-xxl md:text-xl font-bold md:mt-3 station-block-title station-block-title66DCE6">{'Sourcing Expertise'}</p>
            <p className="text-white  mt-14 md:w-full text-base md:text-ssm md:text-left list-disc">
              {
                'We will provide you with a sourcing report as an Excel or PDF, which will include a list of qualified factories with 100% transparency. In addition, we offer manufacturer details such as the ability to sort manufacturers by various criteria, view their top products and markets, and factory details such as production capacity and number of workers. Our product details section includes product photos and pricing information to ensure you have a comprehensive understanding of the products.'
              }
            </p>
          </div>
        </div>
      </div>
      <div className="g-home-block g-station-block bg-white ">
        <div className="g-content flex justify-between  md:flex-col-reverse">
          <div className="flex flex-col justify-center ">
            <p className="text-black-c text-xxl md:text-xl font-bold md:mt-3 station-block-title station-block-title4C6AFF">
              {'Supply Chain Advantage'}
            </p>
            <p className="text-black-c mt-14 md:w-full text-base md:text-ssm md:text-left list-disc">
              {
                'Our global manufacturing capabilities span multiple regions, including Cambodia, Vietnam, and Guangzhou, each with its own unique production advantages. By strategically leveraging these resources, we are able to offer our customers unparalleled price advantages across a wide range of products. With our commitment to quality and efficiency, we are able to provide our customers with the best possible solutions to their manufacturing needs, no matter where they are located.'
              }
            </p>
          </div>
          <LazyLoadImage className="station-block-img" src={block2} alt="" />
        </div>
      </div>
      <div className="bg-white-b w-full">
        <div className="station-factory g-content mx-auto ">
          <LazyLoadImage className="factory-title md:hidden" src={factoryTitle} alt="" />
          <div className="station-factory-item-box  g-content flex md:flex-col justify-between">
            <div className="station-factory-item mx-auto">
              <LazyLoadImage src={factory1} alt="" />
              <p className="text-black-c text-lg">{'Production Base in Cambodia'}</p>
            </div>
            <div className="station-factory-item mx-auto">
              <LazyLoadImage src={factory2} alt="" />
              <p className="text-black-c text-lg">{'Production Center in Hangzhou'}</p>
            </div>
            <div className="station-factory-item mx-auto">
              <LazyLoadImage src={factory3} alt="" />
              <p className="text-black-c text-lg">{'Production Base in Vietnam'}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="station-dashboard md:mt-40 " style={{ backgroundImage: `url(${dashbordBg})` }}>
        <div className="g-content mx-auto flex md:flex-col justify-between">
          <LazyLoadImage className="station-dashboard-img md:mx-auto md:mt-5" src={dashboardImg} alt="" />
          <div className="station-dashboard-main">
            <p className="station-dashbord-title text-white  text-xxl md:text-base font-bold station-block-title station-block-titleFFDAA5">
              Transparent, Traceable and Systematic
            </p>
            <p className="text-white mt-14 md:w-full text-base md:text-ssm md:text-left list-disc">
              {
                'Our comprehensive approach to customer satisfaction includes real-time tracking of production progress, transparent and traceable order information, and a fully customized system that streamlines the entire process. By utilizing cutting-edge technology and innovative solutions, we are able to reduce production cycles and save time and manpower for customer follow-up. With our commitment to efficiency and transparency, customers can rest assured that their orders are in good hands.'
              }
            </p>
          </div>
        </div>
      </div>
      <div className="g-home-weHave flex flex-col items-center justify-center">
        <div className=" text-black-c font-bold text-xl1 hidden mt-7 md:block">We have sourcing experts with broad category knowledge</div>
        <div className="g-home-weHave-title text-xxxl text-black-c font-bold md:hidden">We have sourcing experts with broad category knowledge</div>
        <div className="g-content mt-50px md:flex-col md:mt-7">
          <div className="hidden md:block">
            <HomeMobileSwiper list={swiperProducts} />
          </div>
          <div className="md:hidden relative">
            <HomePcSwiper list={swiperProducts} />
          </div>
        </div>
        <LazyLoadImage className="g-home-weHave-icon mt-16 mb-3 md:mt-7 md:mb-0" src={aircraft} alt={imgAlt} width="30px" height="30px" />
        <p className="text-base font-bold text-center text-black-f md:mb-12 md:font-normal">more products coming soon……</p>
      </div>
      <div className="g-home-buyers bg-white flex flex-col items-center justify-center  ">
        <div className=" text-black-c font-bold text-xl1 hidden mt-7 md:block">Feedback from buyers</div>
        <div className="g-home-buyers-title text-xxxl text-black-c font-bold md:hidden">Feedback from buyers</div>
        <div className="g-content mt-50px md:flex-col md:mt-7">
          <div className="hidden md:block">
            <HomeMobileSwiper list={swiperBuyers} />
          </div>
          <div className="md:hidden relative">
            <HomeBuyersSwiper list={swiperBuyers} />
          </div>
        </div>
      </div>
      <Foot />
    </div>
  );
}

export default Home;
