import React from 'react';
import {  useSearchParams, useNavigate, useParams, createSearchParams } from 'react-router-dom';
import arrowRight from '../assets/img/arrow-right.png';
export default function BreadMenu(props) {
  const [searchParams] = useSearchParams();
  const { menu } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { category } = params;
  const page = searchParams.get('page')||1;
  const fatherName = searchParams.get('fatherName') || '';
  const categoryName = searchParams.get('categoryName');
  const index = searchParams.get('index');
  const childIndex = searchParams.get('childIndex');
  const goTolist = () => {
    const p = { fatherName, categoryName, index, childIndex, page };
    navigate({
      pathname: `/products/${category}/list`,
      search: `?${createSearchParams(p)}`, 
    });
  };
  const goToCategory=()=>{
    navigate(`/products/${category}`);
  }
  if (menu.length === 2) {
    return (
      <div className=" g-breadcrumb g-content mx-auto ">
        <span onClick={goToCategory}>{menu[0]}</span>
        <img src={arrowRight} alt="" />
        <span>{menu[1]}</span>
      </div>
    );
  }
  if (menu.length === 3) {
    return (
      <div className=" g-breadcrumb g-content mx-auto ">
        <span onClick={goToCategory}>{menu[0]}</span>
        <img src={arrowRight} alt="" />
        {/* <NavLink to={'/products/'+menu[0]+'/list?page='+pageNum+'&fatherName='+menu[0]}>{menu[1]}</NavLink> */}
        <span onClick={goTolist}>{menu[1]}</span>
        <img src={arrowRight} alt="" />
        <span>{menu[2]}</span>
      </div>
    );
  }
}
