import React from 'react';
import Head from '@/components/station/Head';
import Foot from '@/components/Foot';
import { isM } from '@/assets/js/utils';
import {RegisterForm} from '@/components/ModalForm'
import { LazyLoadImage } from "react-lazy-load-image-component";
import neteaseDoor from '@/assets/img/fashion/about/neteaseDoor.png';
import points from '@/assets/img/fashion/about/points.png';
import weDo1 from '@/assets/img/fashion/about/weDo1.png';
import weDo2 from '@/assets/img/fashion/about/weDo2.png';
import weDo3 from '@/assets/img/fashion/about/weDo3.png';
import factory1 from '@/assets/img/fashion/factory1.png';
import factory2 from '@/assets/img/fashion/factory2.png';
import factory3 from '@/assets/img/fashion/factory3.png';
import factoryTitle from '@/assets/img/fashion/about/factoryTitle.png';
import DATA from './data';
const category = window.location.hostname.slice(0, window.location.hostname.indexOf('.'));
// console.log(window.location.hostname,window.location.hostname.indexOf('.'),category,555)
const d = DATA[category];
export default function index() {
  return (
    <div>
      <Head category={category}/>
      <div
        className="g-banner w-full bg-cover bg-white md:text-center md:h-auto station-about-banner"
        style={{ backgroundImage: `url(${'http://cowork-storage-public-cdn.lx.netease.com/common/2022/12/15/65f3730df0f64a919734790021160025.png'})` }}>
        <div className="g-content mx-auto text-black-c md:p-6 md:pt-10">
          <div className="station-about-banner-content mx-auto">
            <p className="station-about-banner-title font-bold text-xxxl md:text-xl1 text-white">
              {d.aboutBannerTitle}
            </p>
            <p className="g-banner-desc mt-6 text-base text-white md:text-sm">
              {d.aboutBannerDesc}
            </p>
          </div>
        </div>
      </div>
      <div className="station-about-whoAreWe py-20 md:py-4">
        <p className="whoAreWe-title text-xxxl md:text-xxl text-black-c font-bold text-center mb-16 md:mb-4">Who are we?</p>
        <div className="g-content mx-auto">
          <div className="whoAreWe-main w-full flex justify-between md:flex-col md:w-10/12 md:mx-auto">
            <div className="whoAreWe-left">
              <LazyLoadImage src={neteaseDoor} alt="" />
            </div>
            <div className="whoAreWe-right flex flex-col justify-between md:mx-auto md:mt-4">
              <LazyLoadImage src={points} alt="" />
              <p className="text-base text-black-c whitespace-pre-line">
                {d.neteaseDoorText}
              </p>
              <a href="https://ir.netease.com/" target="_blank" className="g-btn g-btn-l mt-10  md:mt-6 md:mx-auto" rel="noreferrer">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="station-about-whatWeDo py-20 pt-16 md:py-4">
        <p className="whoAreWe-title text-xxxl md:text-xxl text-black-c font-bold text-center mb-16 md:mb-4">What we do?</p>
        <div className="g-content mx-auto flex md:flex-col justify-between">
          <div className="whatWeDo-item mx-auto">
            <LazyLoadImage src={weDo1} alt="" className="" />
            <p className="text-base md:text-sm text-black-c mt-2">
              We develop innovative technology and sustainability driven manufacturing and supply chain solutions.
            </p>
          </div>
          <div className="whatWeDo-item mx-auto">
            <LazyLoadImage src={weDo2} alt="" className="" />
            <p className="text-base md:text-sm text-black-c mt-2">
              We provide complementary technological and scientific expertise for our partners who share our values.
            </p>
          </div>
          <div className="whatWeDo-item mx-auto">
            <LazyLoadImage src={weDo3} alt="" className="" />
            <p className="text-base md:text-sm text-black-c mt-2">
              We take full responsibility of the services and products we offer, with our clients and providers both.
            </p>
          </div>
        </div>
      </div>
      <div className="station-about-our g-content mx-auto flex justify-between md:flex-col text-black-c md:mx-auto pt-16 pb-48 md:py-4">
        {/* <div className="g-content mx-auto"> */}
        {d.ourMission||(
             <div className="station-about-our-left md:mx-auto">
             <p className="text-xxxl font-bold md:text-xxl">Our Mission</p>
             <p className="text-base mt-8 md:mt-1">
               As an industry leader, EaseSupply's mission is to activate and inspire this creative and sustainable transition of the {d.categoryToLowerCase} and Supply
               Chain world.
             </p>
             <p className="text-base">
               {' '}
               While starting with our ow n actions, we also help our clients, B2C brands and our supplier in their evolution. We inspire their
               creativity(new products, new designs based on sustainable approaches) and empower their success.
             </p>
           </div>
        )}
        <div className="station-about-our-right md:mt-2 md:mx-auto">
          <p className="text-xxxl font-bold md:text-xxl">Our Vision</p>
          <p className="text-base mt-8 md:mt-1">
            Empowering our employees and business partners, serving clients with our philosophy and service in pursuit of excellence, we envision to
            enable a sustainable future for our industry and environment.
          </p>
        </div>
        {/* </div> */}
      </div>
      <div className="station-about-make">
        <LazyLoadImage className="factory-title md:hidden" src={factoryTitle} alt=""/>
        <p className="text-xxxl text-balck-g font-bold mx-auto text-center md:text-xxl">Make a Difference</p>
        <div className="g-content mx-auto">
          <p className="station-about-make-desc mt-4 text-base mx-auto text-black-f text-center md:text-left">
            There are companies in the world that say they value sustainable initiatives, but they are all talk and no action. When we say it, we
            truly mean it, and we are looking for partners who believe in making a difference as well.
          </p>
          <div className="station-about-make-cards pb-14 md:px-4 flex justify-between md:flex-col mt-32 md:mt-2">
            <div>
              <LazyLoadImage src={factory1} alt="" />
              <p className='text-black-c text-lg'>Production Base in Cambodia</p>
            </div>
            <div>
              <LazyLoadImage src={factory2} alt="" />
              <p className='text-black-c text-lg'>Production Center in Hangzhou</p>
            </div>
            <div>
              <LazyLoadImage src={factory3} alt="" />
              <p className='text-black-c text-lg'>Production Base in Vietnam</p>
            </div>
          </div>
        </div>
      </div>
      <div className="station-about-from mt-56 md:mt-4">
        <div className="station-about-from-main mx-auto" >
            <RegisterForm category={category}/>
        </div>
      </div>
      <Foot />
    </div>
  );
}
