import React, { useEffect, useState } from 'react';
import './App.less';
import { useRoutes, useSearchParams } from 'react-router-dom';
import data from './router';
import ajax from './request';
import { checkAccessCode, setLocalStorage } from '@/assets/js/utils';
export const productsContext = React.createContext(null);

function Router() {
  const [searchParams] = useSearchParams();
  const ac = searchParams.get('ac');
  const isValid = checkAccessCode(ac);
  if (ac&&isValid === true) {
    setLocalStorage('ac', ac);
  }
//   if("window.location.pathname!=='/'"){
//     document.documentElement.scrollTop = document.body.scrollTop = 0;
//   }
 document.documentElement.scrollTop = document.body.scrollTop = 0;
  return useRoutes(data);
}
function App() {
  const [data, setData] = useState(null);
  useEffect(() => {
    ajax.getCategory().then(res => {
      setData(res);
    //   document.dispatchEvent(new Event('render-event'));//加载完发布这个事件
    });
  }, []);
  return (
    <productsContext.Provider value={data}>
      <Router />
    </productsContext.Provider>
  );
}

export default App;
