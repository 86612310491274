import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperItem, Icon } from '@nutui/nutui-react';
export default function HomeMobileSwiper({ list }) {
  const naviagte = useNavigate();
  const swiperRef = React.useRef(null);
  const [current2, setCurrent2] = useState(1);
  const handlePrev = () => {
    swiperRef.current.prev();
  };
  const handleNext = () => {
    swiperRef.current.next();
  };
  const pageStyle = {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '46px',
    height: '22px',
    background: 'rgba(0, 0, 0, 0.33)',
    borderRadius: '22px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '14px',
  };
  const btnsStyle = {
    opacity: 0,
    width: '100%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(30px)',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    height: '0px',
  };
  const spanStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '30px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  };
  return (
    <div className="demo-box mx-auto" style={{ height: '98vw', width: '98vw', position: 'relative' }}>
      {/* <div className="hidden md:block p-4 ">
        <Icon
          name="left"
          onClick={() => {
            naviagte(-1);
          }}
        />
      </div> */}
      <Swiper
        //   height={'90vw'}
        //   width={'90vw'}
        isCenter={true}
        ref={swiperRef}
        loop
        initPage={0}
        onChange={e => setCurrent2(e + 1)}
        pageContent={
          <div style={pageStyle}>
            {' '}
            {current2}/{list.length}{' '}
          </div>
        }
      >
        {list.map((item, index) => {
          return (
            <SwiperItem key={index}>
              <img
                src={item?.images + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100'}
                alt=""
                className="mx-auto"
                style={{ width: '98vw', height: '98vw', border: '1px solid #eee' }}
              />
            </SwiperItem>
          );
        })}
      </Swiper>
      <div style={btnsStyle}>
        <span style={spanStyle} onClick={e => handlePrev()}>
          <Icon name="left" />
        </span>
        <span style={spanStyle} onClick={e => handleNext()}>
          <Icon name="right" />
        </span>
      </div>
    </div>
  );
}
