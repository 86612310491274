import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import BreadMenu from '@/components/BreadMenu';
import ModalForm from '@/components/ModalForm';
import { Skeleton } from 'antd';
import { Swiper, SwiperItem, Icon } from '@nutui/nutui-react';
import ajax from '@/request';
import { setVisble, setHref } from '@/store/slices/modalFormSlice';
const MobileSwiper = ({ images }) => {
  const naviagte = useNavigate();
  const swiperRef = React.useRef(null);
  const [current2, setCurrent2] = useState(1);
  const handlePrev = () => {
    swiperRef.current.prev();
  };
  const handleNext = () => {
    swiperRef.current.next();
  };
  const pageStyle = {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '46px',
    height: '22px',
    background: 'rgba(0, 0, 0, 0.33)',
    borderRadius: '22px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '14px',
  };
  const btnsStyle = {
    opacity: 0,
    width: '100%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(30px)',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    height: '0px',
  };
  const spanStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '30px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  };

  //   let list = [
  //     'https://storage.360buyimg.com/jdc-article/NutUItaro34.jpg',
  //     'https://storage.360buyimg.com/jdc-article/NutUItaro2.jpg',
  //     'https://storage.360buyimg.com/jdc-article/welcomenutui.jpg',
  //     'https://storage.360buyimg.com/jdc-article/fristfabu.jpg',
  //   ];
  return (
    <div className="demo-box mx-auto" style={{ height: '98vw', width: '98vw', position: 'relative' }}>
      <div className="hidden md:block p-4 ">
        <Icon
          name="left"
          onClick={() => {
            naviagte(-1);
          }}
        />
      </div>
      <Swiper
        //   height={'90vw'}
        //   width={'90vw'}
        isCenter={true}
        ref={swiperRef}
        loop
        initPage={0}
        onChange={e => setCurrent2(e + 1)}
        pageContent={
          <div style={pageStyle}>
            {' '}
            {current2}/{images?.length}{' '}
          </div>
        }
      >
        {images.map((item, index) => {
          return (
            <SwiperItem key={index}>
              <img
                src={
                  item
                    ? item + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100'
                    : 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/03/02/1fbda02e016d4bb383dda7bded575a7c.png'
                }
                alt=""
                className="mx-auto"
                style={{ width: '98vw', height: '98vw', border: '1px solid #eee' }}
              />
            </SwiperItem>
          );
        })}
      </Swiper>
      <div style={btnsStyle}>
        <span style={spanStyle} onClick={e => handlePrev()}>
          <Icon name="left" />
        </span>
        <span style={spanStyle} onClick={e => handleNext()}>
          <Icon name="right" />
        </span>
      </div>
    </div>
  );
};
export default function Details() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const pathnameMark = pathname.split('/')[2];
  const [data, setData] = useState(null);
  const id = searchParams.get('id');
  const fatherName = searchParams.get('fatherName');
  const categoryName = searchParams.get('categoryName');
  const [currentImg, setCurrentImg] = useState(0);
  const imageUrlArr = (data?.imageUrl && data.imageUrl?.split(';')) || [];
  const handlerSwitchImg = n => {
    if (n === -1 && currentImg === 0) {
      return;
    }
    if (n === 1 && currentImg >= imageUrlArr.length - 1) {
      return;
    }
    setCurrentImg(currentImg + n);
  };
  const calcImgPos = () => {
    if (currentImg >= 16) {
      return -1600;
    }
    if (currentImg >= 8) {
      return -800;
    }
    if (currentImg >= 4) {
      return -400;
    } else {
      return 0;
    }
  };
  const showModalForm = e => {
    e.stopPropagation();
    dispatch(setVisble(true));
    dispatch(setHref(window.location.href));
  };
  useEffect(() => {
    ajax.getCollectionDetail({ id }).then(res => {
      if (res?.result) {
        setData(res.result);
      }
    });
  }, []);
  const DescStr_diff = {
    toys: `${data?.portOfLoading ? 'Port of Loading | ' + data?.portOfLoading + ';' : ''}${data?.moq ? 'MOQ | ' + data?.moq + ';' : ''}${
      data?.weight ? 'Weight | ' + data?.weight + 'KG;' : ''
    }${data?.packing ? 'Packing | ' + parseInt(data?.packing) + ' ' + data?.packingUnit + ';' : ''}${data?.age ? 'Age | ' + data?.age + ';' : ''}${
      data?.dimension ? data?.dimension : ''
    }`, // 潮玩描述字段合集
    outdoor: `${data?.gp20 ? 'Loading - 20 GP | ' + data?.gp20 + ';' : ''}${data?.gp40 ? 'Loading - 40 GP | ' + data?.gp40 + ';' : ''}${
      data?.hc40 ? 'Loading - 40 HC | ' + data?.hc40 + ';' : ''
    }${data?.moq ? 'MOQ | ' + data?.moq + ';' : ''}${data?.packing ? 'Packing | ' + parseInt(data?.packing) + ' ' + data?.packingUnit + ';' : ''}${
      data?.portOfLoading ? 'Port of Loading | ' + data?.portOfLoading : ''
    }`, // outdoor描述字段合集
  };
  const Specifications_diff = {
    toys: data?.additionalFeatures + data?.specification, // 潮玩Specifications字段合集
    outdoor: `${data?.dimension ? data?.dimension + ';' : ''}${data?.weight ? 'Weight | ' + data?.weight + 'KG;' : ''}${data?.specification ||
      ''}${data?.additionalFeatures || ''}`, // outdoorSpecifications字段合集
  };
  return data ? (
    <div>
      <ModalForm />
      {/* <div className="md:hidden">{<BreadMenu menu={[fatherName, categoryName, data.productName]} />}</div> */}
      <div className="hidden md:block">
        <MobileSwiper images={imageUrlArr} />
      </div>
      <div className="g-content mx-auto mt-5">
        <div className="g-details-goods flex">
          <div className="g-details-goods-switch md:hidden">
            <div className="g-details-goods-switch-icon" onClick={() => handlerSwitchImg(-1)} />
            <div style={{ height: '444px', overflow: 'hidden', margin: '18px 0' }}>
              <div className="g-details-goods-switch-imgs relative" style={{ top: calcImgPos() + 'px' }}>
                {imageUrlArr.map((img, i) => (
                  <img
                    key={i}
                    style={{ border: currentImg === i ? '3px solid black' : 'none' }}
                    src={
                      img
                        ? img + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100'
                        : 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/03/02/1fbda02e016d4bb383dda7bded575a7c.png'
                    }
                    alt=""
                    onClick={() => setCurrentImg(i)}
                  />
                ))}
              </div>
            </div>
            <div className="g-details-goods-switch-icon switch-next" onClick={() => handlerSwitchImg(1)} />
          </div>
          <div className="g-details-goods-mainImg ml-10 md:hidden">
            <img
              className="w-full h-full"
              src={
                imageUrlArr
                  ? imageUrlArr[currentImg] + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100'
                  : 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/03/02/1fbda02e016d4bb383dda7bded575a7c.png'
              }
              alt=""
            />
          </div>
          <div className="g-details-goods-text ml-12 md:ml-0 md:px-4">
            <p className="text-xl text-black-c font-bold md:text-base md:mt-10">{data.productName}</p>
            <div className="g-details-goods-attr text-base text-black-c md:text-sm">
              <p>
                {/* <span>{pathnameMark === 'beauty' ? 'SKU' : 'Item No.'}</span> */}
                <span>Item No.</span>
                <span>{data.itemNumber}</span>
              </p>
              {/* 潮玩显示 exw  户外显示 fob  未来有其他 品类|计价方式 */}
              {pathname.includes('toys') && (
                <p>
                  <span>Estimated EXW Price</span>
                  <span>
                    {data.exw ? (
                      data.exwCurrency + ' ' + data.exw
                    ) : (
                      <span onClick={showModalForm} className="cursor-pointer" style={{ color: '#6575F8', textDecoration: 'none' }}>
                        Get a quote &gt;
                      </span>
                    )}
                  </span>
                </p>
              )
              // fatherName+categoryName
              }
              {pathname.includes('outdoor') && (
                <p>
                  <span>Estimated FOB Price</span>
                  <span>
                    {data.fob ? (
                      data.fob
                    ) : (
                      <span onClick={showModalForm} className="cursor-pointer" style={{ color: '#6575F8', textDecoration: 'none' }}>
                        Get a quote &gt;
                      </span>
                    )}
                  </span>
                </p>
              )}
              {/* {pathname.includes('beauty') && (
                <p>
                  <span>Estimated Price</span>
                  <span>
                    <span onClick={showModalForm} className="cursor-pointer" style={{ color: '#6575F8', textDecoration: 'none' }}>
                      Get a quote &gt;
                    </span>
                  </span>
                </p>
              )} */}
              <p>
                <span>Core Specifications</span>
              </p>

              {/* {!pathname.includes('beauty') && (
                <p>
                  <span>Core Specifications</span>
                </p>
              )} */}
              <div className="g-details-goods-attr-spec text-sm font-normal">
                {/* {pathnameMark === 'beauty' ? (
                  <>
                    {data?.capacity && (
                      <p className="flex justify-between">
                        <span>Capacity</span>
                        <span style={{ color: '#999' }}>{data.capacity + ' ' + (data?.capacityUnit || '')}</span>
                      </p>
                    )}
                    {data?.moq && (
                      <p className="flex justify-between">
                        <span>MOQ</span>
                        <span style={{ color: '#999' }}>{data.moq}</span>
                      </p>
                    )}
                    {data?.goodsSizeList?.length > 0 && (
                      <p className="flex justify-between">
                        <span>Size</span>
                        <span style={{ color: '#999' }}>
                          {data?.goodsSizeList?.map((sizeitem, index) => {
                            return (
                              <p key={index} className="text-right">
                                {`${sizeitem.name || ''} ${sizeitem?.length || ''}${
                                  !sizeitem.width ? '' : (!!sizeitem.width || !!sizeitem.height) && !!sizeitem.length ? 'x' : ''
                                }${sizeitem.width || ''}${(!!sizeitem.width || !!sizeitem.length) && !!sizeitem.height ? 'x' : ''}${sizeitem.height ||
                                  ''}${sizeitem.unit || ''}`}
                              </p>
                            );
                          })}
                        </span>
                      </p>
                    )}
                    {data?.declareLogos && (
                      <div className="flex" style={{ marginTop: '12px' }}>
                        {(data?.declareLogos || '')
                          .split(';')
                          .slice(0, 5)
                          .map((item, index) => {
                            return <img src={item} key={item + index} width="74" height="110" style={{ marginRight: '24px' }} />;
                          })}
                      </div>
                    )}
                  </>
                ) : (
                  (DescStr_diff[pathnameMark] || '')
                    .split(';')
                    .slice(0, 6)
                    .map((item, index) => {
                      // console.log(item, 'item');
                      return (
                        <p key={index} className="flex justify-between">
                          <span>{item.split('|')[0]}</span>
                          <span style={{ color: '#999' }}>{item.split('|')[1]}</span>
                        </p>
                      );
                    })
                )} */}
                {(DescStr_diff[pathnameMark] || '')
                  .split(';')
                  .slice(0, 6)
                  .map((item, index) => {
                    // console.log(item, 'item');
                    return (
                      <p key={index} className="flex justify-between">
                        <span>{item.split('|')[0]}</span>
                        <span style={{ color: '#999' }}>{item.split('|')[1]}</span>
                      </p>
                    );
                  })}
              </div>
            </div>
            <div onClick={showModalForm} className="g-btn g-btn-xl">
              Send us an inquiry
            </div>
          </div>
        </div>
      </div>
      {(Specifications_diff[pathnameMark] || '') && (
        <div className="g-detalis-spec g-content mx-auto">
          <div className="g-detalis-spec-title w-full">Specifications</div>
          {(Specifications_diff[pathnameMark] || '')?.split('factory_163_specification_category ').map((item, index) => {
            if (!item) return '';
            return (
              <div key={index} style={{ marginTop: '74px' }} className="flex flex-wrap relative g-details-table md:mx-4">
                {item.split(';').map((i, k, arr) => {
                  if (!i) return '';
                  if (i.indexOf('|') === 0) {
                    return (
                      //表格标题
                      <div key={k} className="text-lg text-black-c font-bold absolute -top-12 left-0 md:text-base">
                        {i.slice(1)}
                      </div>
                    );
                  }
                  return (
                    //   <div className='flex' key={k} >
                    <p
                      key={k}
                      style={{
                        height: '50px',
                        background: '#F8F8F8',
                        //   borderBottom: k === arr.length - 1 ? 'none' : '1px solid #E9E9E9',
                        borderBottom: '1px solid #E9E9E9',
                      }}
                      className="g-detail-table-unit flex items-center w-1/2 md:w-full"
                    >
                      <span
                        style={{ paddingLeft: '12px', borderRight: '1px solid #E9E9E9', width: '200px' }}
                        className="w-1/2 h-full flex items-center"
                      >
                        {' '}
                        {i.split('|')[0]}
                      </span>
                      <span
                        style={{ paddingLeft: '12px', borderRight: '1px solid #E9E9E9', width: '400px' }}
                        className="w-1/2  h-full flex items-center bg-white"
                      >
                        {' '}
                        {i.split('|')[1]}
                      </span>
                    </p>
                    //   </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {
        <div className="g-content mx-auto">
          {data?.sellingPoints && <div className="g-detalis-spec-title w-full mt-20">Selling Points</div>}
          {data?.sellingPoints?.split('\n').map((item, index) => {
            return (
              <p key={item + index} className="g-detalis-desc text-black-f text-base pt-5 md:pb-6">
                {item}
              </p>
            );
          })}
        </div>
      }
      <div className="g-content mx-auto">
        {data?.desc && <div className="g-detalis-spec-title w-full mt-20">Description</div>}
        {data?.desc && <div dangerouslySetInnerHTML={{ __html: data?.desc }} />}
        {/* {data?.desc?.split('\n').map(item => {
          return <p className="g-detalis-desc text-black-f text-base pt-5 md:pb-6">{item}</p>;
        })} */}
      </div>
      <div
        className="g-content mx-auto pb-20 md:w-full md:mt-7 md:mb-0"
        style={{ marginBottom: '30px', justifyContent: 'center', flexWrap: 'wrap', display: 'flex' }}
      >
        {data?.imageDescUrl?.split('\n').map(item => {
          return <img style={{ width: '40vw', minWidth: '160px', height: 'auto', margin: 10 }} src={item} />;
        })}
      </div>
    </div>
  ) : (
    <div className="flex flex-col justify-evenly" style={{ height: '80vh' }}>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );
}
