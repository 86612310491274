export const swiperProducts = [
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/16/937c80b4e1d8464db2e40335c1fbe3cd.png',
    title: 'Outdoor Living',
    text: 'Outdoor Furniture | Camping | Outdoor Lighting | Gardening',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/16/feaa946a344248c4aae5a1b695354e27.png',
    title: 'Fashion',
    text: 'T-shirts | Hoodies | Shirts | Jackets | Denim | Sleepwear | Activewear | Swimwear',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/16/982be8c2f1ff4560a08ca5983a938366.png',
    title: 'Toys & Hobbies',
    text: 'Educational Toys | Anime & Comics | Building Toys | Pop Culture & Arts | Plush',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/e383107e4417439c8d526c38e89be914.png',
    title: 'Beauty',
    text: 'Color cosmetics | Skin care | Makeup tools | False lashes | Color contact lenses | Perfume | Wig | Packaging',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/16/937c80b4e1d8464db2e40335c1fbe3cd.png',
    title: 'Outdoor Living',
    text: 'Outdoor Furniture | Camping | Outdoor Lighting | Gardening',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/16/feaa946a344248c4aae5a1b695354e27.png',
    title: 'Fashion',
    text: 'T-shirts | Hoodies | Shirts | Jackets | Denim | Sleepwear | Activewear | Swimwear',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/16/982be8c2f1ff4560a08ca5983a938366.png',
    title: 'Toys & Hobbies',
    text: 'Educational Toys | Anime & Comics | Building Toys | Pop Culture & Arts | Plush',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/e383107e4417439c8d526c38e89be914.png',
    title: 'Beauty',
    text: 'Color cosmetics | Skin care | Makeup tools | False lashes | Color contact lenses | Perfume | Wig | Packaging',
  },
];
export const swiperBuyers = [
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/4d191c78465b427590441c64e4a02f46.png',
    name: 'Allison M.',
    jobs: 'Vice President',
    text:
      'My original manufacturer of three years abruptly raised their prices by 25%, then I discovered the excellent sourcing service provided by EaseSupply. With their help, I was able to explore alternative suppliers and even came across new products that were an ideal fit for my holiday programs.',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/4070bee7a4144eb181aff75c3c0f2769.png',
    name: 'Phillip J.',
    jobs: 'Product Manager',
    text:
      "The arrival of EaseSupply couldn't have been more timely, given that COVID restrictions had made it impossible for me to travel to China. As my products are quite technical, I was thoroughly impressed with EaseSupply's ability to identify vendors who met my high technical standards.",
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/fdeb6f8769764ba3b87939c1f44d40a7.png',
    name: 'Grace A.',
    jobs: 'Assistant Buyer',
    text:
      'Having sourced from China for more than a decade, I can attest that this service has saved me a tremendous amount of time. The manufacturers and products that EaseSupply have identified for me have been absolutely fantastic. I would strongly urge others to consider EaseSupply’s services!',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/286ebdc7afa8496a8e946eb684914c63.png',
    name: 'Mellisa H.',
    jobs: 'Purchasing Manager',
    text:
      'My experience was excellent - the service was both fast and efficient. I was surprised to receive a comprehensive report within a week, which was well-organized and contained all the necessary information. Additionally, I was able to obtain information on a custom request, all without having to visit China.',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/4d191c78465b427590441c64e4a02f46.png',
    name: 'Allison M.',
    jobs: 'Vice President',
    text:
      'My original manufacturer of three years abruptly raised their prices by 25%, then I discovered the excellent sourcing service provided by EaseSupply. With their help, I was able to explore alternative suppliers and even came across new products that were an ideal fit for my holiday programs.',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/4070bee7a4144eb181aff75c3c0f2769.png',
    name: 'Phillip J.',
    jobs: 'Product Manager',
    text:
      "The arrival of EaseSupply couldn't have been more timely, given that COVID restrictions had made it impossible for me to travel to China. As my products are quite technical, I was thoroughly impressed with EaseSupply's ability to identify vendors who met my high technical standards.",
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/fdeb6f8769764ba3b87939c1f44d40a7.png',
    name: 'Grace A.',
    jobs: 'Assistant Buyer',
    text:
      'Having sourced from China for more than a decade, I can attest that this service has saved me a tremendous amount of time. The manufacturers and products that EaseSupply have identified for me have been absolutely fantastic. I would strongly urge others to consider EaseSupply’s services!',
  },
  {
    images: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/04/18/286ebdc7afa8496a8e946eb684914c63.png',
    name: 'Mellisa H.',
    jobs: 'Purchasing Manager',
    text:
      'My experience was excellent - the service was both fast and efficient. I was surprised to receive a comprehensive report within a week, which was well-organized and contained all the necessary information. Additionally, I was able to obtain information on a custom request, all without having to visit China.',
  },
];
