import { configureStore } from '@reduxjs/toolkit';
import counterSlice from './slices/counterSlice';
import productsSlice from './slices/productsSlice';
import modalFormSlice from './slices/modalFormSlice';

const store = configureStore({
  reducer: {
    counter: counterSlice,
    products: productsSlice,
    modalForm: modalFormSlice,
  },
});

export default store;
