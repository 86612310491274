import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Controller } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
const imgAlt = 'NetEase';
export default function HomeBuyersSwiper({ list }) {
  const left = {
    left: '-40px',
  };
  const right = {
    right: '-20px',
  };
  return (
    <div className="buyers" style={{ height: '492px' }}>
      <Swiper
        className="buyers"
        key="buyears"
        modules={[Navigation, Controller]}
        spaceBetween={10}
        slideToClickedSlide={true}
        centeredSlides={true}
        slidesPerView={3}
        loop={true}
        initialSlide={1}
        onSlideChange={() => console.log('slide change')}
        navigation={{
          nextEl: '.buyers .swiper-button-next',
          prevEl: '.buyers .swiper-button-prev',
        }}
      >
        {list.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              {/* {boxStyle1}  */}
              <div style={{ width: '369px', height: '410px', border: '1px solid #A0AEF0', borderRadius: '11px', padding: '20px', color: '#000000' }}>
                <div className="flex flex-row">
                  <img src={item?.images} alt="imgAlt" style={{ width: '160px', height: '160px' }} />
                  <div style={{ paddingLeft: '20px' }}>
                    <div style={{ marginBottom: '22px', fontSize: '24px', paddingTop: '32px', fontWeight: 700 }}>{item.name}</div>
                    <div style={{ fontSize: '18px' }}>{item.jobs}</div>
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: '28px',
                    color: '#000000',
                    padding: '4px',
                    marginTop:'20px',
                    height: '160px',
                    fontSize: '16px',
                  }}
                >
                  {item?.text}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={'buyers swiper-button-prev'} style={left} />
      <div className={'buyers swiper-button-next'} style={right} />
    </div>
  );
}
