import React, { useState } from 'react';
import { SideNavBar, SubSideNavBar, SideNavBarItem } from '@nutui/nutui-react';
import { useDispatch } from 'react-redux';
import { setVisble, setHref } from '@/store/slices/modalFormSlice';
import { CaretDownOutlined } from '@ant-design/icons';
import { Link, useNavigate, NavLink, useLocation } from 'react-router-dom';
import ModalForm from '@/components/ModalForm';
import logo from '../assets/img/logo.png';
import m_logo from '../assets/img/m_logo.png';
import navMenu from '../assets/img/home/navMenu.png';
import { Sticky } from '@nutui/nutui-react';
export default function Head() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const showModalForm = () => {
    dispatch(setVisble(true));
    dispatch(setHref(window.location.host));
  };
  const getNavColor = path => {
    if (!path) {
      return {};
    }
    if (pathname === path) {
      return { color: '#5E79FF' };
    } else if (path === '/category' && (pathname.includes('/category') || pathname.includes('/products'))) {
      return { color: '#5E79FF' };
    } else if (path === '/fashion' && pathname.includes('fashion')) {
      return { color: '#5E79FF' };
    } else if (path === '/outdoor' && pathname.includes('outdoor')) {
      return { color: '#5E79FF' };
    } else if (path === '/toys' && pathname.includes('toys')) {
      return { color: '#5E79FF' };
    } else if (path === '/beauty' && pathname.includes('beauty')) {
      return { color: '#5E79FF' };
    } else if (path === '/products' && pathname.includes('products')) {
      return { color: '#5E79FF' };
    }
  };
  const [navBarState, setNavBarState] = useState({
    visible: false,
    position: 'left',
  });
  const changeNarBar = (visible, position = navBarState.position) => {
    setNavBarState({
      visible,
      position,
    });
  };
  return (
    <>
      <div className="hidden md:block">
        <Sticky>
          <div
            className="h-11 bg-white flex justify-between items-center px-6 shadow"
            style={{ width: '100vw', borderBottom: '1px solid #eee' }}
            onClick={() => {
              changeNarBar(true, 'left');
            }}
          >
            <img src={m_logo} alt="" style={{ height: '24px' }} />
            {/* <span className="text-base font-bold text-black-c -translate-x-16">EaseSupply</span> */}
            {/* <Icon name="horizontal"></Icon> */}
            <img src={navMenu} alt="" style={{ height: '12px' }} />
          </div>
          <SideNavBar
            className="font-bold text-base"
            title="EaseSupply"
            visible={navBarState.visible}
            position={navBarState.position}
            onClose={() => {
              changeNarBar(false);
            }}
          >
            <SubSideNavBar className="m-nav-home font-bold" title="Home" ikey="1-01" onClick={() => navigate('/')} style={getNavColor('/')} />
            <SubSideNavBar className="font-bold" title="Products" ikey="1-0" style={getNavColor('/products')}>
              <SideNavBarItem
                title="Sustainable Fashion"
                ikey="1-01"
                onClick={() => {
                  changeNarBar(false);
                  navigate('/products/fashion');
                }}
                style={{ color: '#111', fontWeight: '400', ...getNavColor('/fashion') }}
              />
              <SideNavBarItem
                title="Outdoor Living"
                ikey="1-02"
                onClick={() => {
                  changeNarBar(false);
                  navigate('/products/outdoor');
                }}
                style={{ color: '#111', fontWeight: '400', ...getNavColor('/outdoor') }}
              />
              <SideNavBarItem
                title="Toys & Hobbies"
                ikey="1-03"
                onClick={() => {
                  changeNarBar(false);
                  navigate('/products/toys');
                }}
                style={{ color: '#111', fontWeight: '400', ...getNavColor('/toys') }}
              />
              <SideNavBarItem
                title="Beauty"
                ikey="1-04"
                onClick={() => {
                  changeNarBar(false);
                  navigate('/products/beauty');
                }}
                style={{ color: '#111', fontWeight: '400', ...getNavColor('/beauty') }}
              />
            </SubSideNavBar>
            {/* <SubSideNavBar
              className="m-nav-meeting font-bold"
              title="About Us"
              ikey="1-00"
              onClick={() => {
                changeNarBar(false);
                window.location.href = '/#about-us';
              }}
            /> */}
            <SubSideNavBar
              className="m-nav-meeting font-bold"
              title="Schedule a Meeting"
              ikey="1-01"
              onClick={() => {
                changeNarBar(false);
                showModalForm();
              }}
            />
          </SideNavBar>
        </Sticky>
      </div>

      <div className="g-header  bg-white w-full text-base font-bold md:hidden">
        <ModalForm />
        <div className="g-content h-full mx-auto flex items-center text-black-c ">
          <img src={logo} alt="logo" height="24px" width="26.56px" />
          <span className="ml-5">EaseSupply</span>
          <div className="g-header-nav h-full m-0 flex items-center relative">
            <span style={getNavColor('/')}>
              <Link to="/">Home</Link>
            </span>
            <span className="g-header-nav-pro" style={getNavColor('/category')}>
              Products <CaretDownOutlined className="relative" style={{ top: '-2px', transform: 'scale(.6)' }} />
            </span>
            <span onClick={showModalForm}>Schedule a Meeting</span>
            <div className="g-header-nav-menu-box absolute">
              <div className="g-header-nav-menu bg-white flex flex-col text-sm text-black-c  font-normal ">
                <NavLink to="/products/fashion" style={({ isActive }) => ({ color: isActive ? '' : '' })}>
                  Sustainable Fashion
                </NavLink>
                <NavLink to="/products/outdoor">Outdoor Living</NavLink>
                <NavLink to="/products/toys">Toys & Hobbies</NavLink>
                <NavLink to="/products/beauty">Beauty</NavLink>
              </div>
            </div>
          </div>
          <div
            style={{
              background: '#5E79FF',
              cursor: 'pointer',
              color: '#fff',
              padding: '6px',
              borderRadius: '5px',
              marginLeft: 'auto',
              fontSize: '14px',
            }}
            onClick={showModalForm}
          >
            供应商入驻
          </div>
        </div>
      </div>
    </>
  );
}
