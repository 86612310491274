import React, { useState } from 'react';
import { SideNavBar, SubSideNavBar, SideNavBarItem } from '@nutui/nutui-react';
import { useDispatch } from 'react-redux';
import { setVisble, setHref } from '@/store/slices/modalFormSlice';
import { CaretDownOutlined } from '@ant-design/icons';
import { Link, useNavigate, NavLink, useLocation } from 'react-router-dom';
import ModalForm from '@/components/ModalForm';
import logo from '@/assets/img/logo.png';
import m_logo from '@/assets/img/m_logo.png';
import navMenu from '@/assets/img/home/navMenu.png';
import { Sticky } from '@nutui/nutui-react';
export default function Head({ category }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const showModalForm = () => {
    dispatch(setVisble(true));
    dispatch(setHref(window.location.host));
  };
  const getNavColor = path => {
    if (!path) {
      return {};
    }
    if (pathname === path) {
      return { color: '#5E79FF' };
    } else if (path === '/category' && (pathname.includes('/category') || pathname.includes('/products'))) {
      return { color: '#5E79FF' };
    } else if (path === '/fashion' && pathname.includes('fashion')) {
      return { color: '#5E79FF' };
    } else if (path === '/outdoor' && pathname.includes('outdoor')) {
      return { color: '#5E79FF' };
    } else if (path === '/toys' && pathname.includes('toys')) {
      return { color: '#5E79FF' };
    } else if (path === '/beauty' && pathname.includes('beauty')) {
      return { color: '#5E79FF' };
    } else if (path === '/products' && pathname.includes('products')) {
      return { color: '#5E79FF' };
    }
  };
  const [navBarState, setNavBarState] = useState({
    visible: false,
    position: 'left',
  });
  const changeNarBar = (visible, position = navBarState.position) => {
    setNavBarState({
      visible,
      position,
    });
  };
  return (
    <>
      <div className="hidden md:block">
        <Sticky>
          <div
            className="h-11 bg-white flex justify-between items-center px-6 shadow"
            style={{ width: '100vw', borderBottom: '1px solid #eee' }}
            onClick={() => {
              changeNarBar(true, 'left');
            }}
          >
            <img src={m_logo} alt="" style={{ height: '24px' }} />
            {/* <span className="text-base font-bold text-black-c -translate-x-16">EaseSupply</span> */}
            {/* <Icon name="horizontal"></Icon> */}
            <img src={navMenu} alt="" style={{ height: '12px' }} />
          </div>
          <SideNavBar
            className="font-bold text-base"
            title="EaseSupply"
            visible={navBarState.visible}
            position={navBarState.position}
            onClose={() => {
              changeNarBar(false);
            }}
          >
            <SubSideNavBar className="m-nav-home font-bold" title="Home" ikey="1-01" onClick={() => navigate('/')} style={getNavColor('/')} />
            <SubSideNavBar
              className="font-bold"
              title="Products"
              ikey="1-0"
              onClick={() => navigate('/products/' + category)}
              style={getNavColor('/products')}
            />
            {/* <SubSideNavBar
              className="m-nav-meeting font-bold"
              title="About Us"
              ikey="1-00"
              style={getNavColor('/about')}
              onClick={() => navigate('/about')}
            /> */}
            <SubSideNavBar
              className="m-nav-meeting font-bold"
              title="Schedule a Meeting"
              ikey="1-01"
              onClick={() => {
                changeNarBar(false);
                showModalForm();
              }}
            />
          </SideNavBar>
        </Sticky>
      </div>

      <div className="g-header bg-white w-full text-base font-bold md:hidden sticky top-0  z-10">
        <ModalForm category={category} />
        <div className="g-content h-full mx-auto flex items-center text-black-c ">
          <Link to="/">
            <img src={logo} alt="logo" height="24px" width="26.56px" />
          </Link>
          <Link to="/">
            <span className="ml-5">EaseSupply</span>
          </Link>
          <div className="g-header-nav h-full m-0 flex items-center relative">
            <span style={getNavColor('/')}>
              <Link to="/">Home</Link>
            </span>
            <span>
              <NavLink to={'/products/' + category} style={getNavColor('/products')}>
                Products
              </NavLink>
            </span>
            <span onClick={showModalForm}>Schedule a Meeting</span>
          </div>
        </div>
      </div>
    </>
  );
}
