import { instance } from './instance';

const ajax = {
  /* 主站商品数据 */
  getCategory: async function (params) {
    return await instance.get(`/api/factoryCategory/queryCategory`).then(res => res?.result);
  },
  /* 精选集商品 */
  getCollectionList: async function (params) {
    return await instance.get(`/api/official/collection`,{
        params,
      }).then(res => res?.result);
  },
   /* 精选集商品详情 */
   getCollectionDetail:async function(params){
    return await instance
    .get(`/api/official/goods/detail`, {
      params,
    })
    .then(res => res);
  },
  // 独立站首页商品
  getCategoryStation: async function (category) {
    const path={
        fashion:'queryFashionCategory',
        beauty:'queryBeautyCategory',
        fabric:'queryFabricCategory',
        outdoor:'queryOutdoorCategory',
        toys:'queryToysCategory'
    }
    return await instance.get(`/api/factoryCategory/${path[category]}`).then(res => res?.result);
  },
  /* 主站商品列表 */
  getProducts: async function (key, data) {
    const urls = {
      garments: 'Sustainable Fashion',
      outdoorFurniture: 'Outdoor Living',
      toysHobbies:'Toys & Hobbies',
      fabric:'Fabric',
      beauty:'Beauty',
    };
    //   .post(`/api/factoryGoods/${urls[key]}`, data)
    return await instance
      .post(`/api/factoryGoods/queryGoodsList`, {...data,businessLine:urls[key]})
      .then(res => res.result);
  },
  /* 主站详情 */
  getDetail: async function (pathname, params) {
    let path=''
    if(pathname.includes('outdoor')){
        path='queryOutdoorFurnitureDetails' 
    }
    if(pathname.includes('toys')){
        path='queryToysHobbiesDetails'
    }
    if(pathname.includes('beauty')){
        path='queryBeautyDetails'
    }
    if(pathname.includes('fabric')){
        path='queryFabricDetails'
    }
    // .get(`/api/factoryGoods/${path}`, {
    return await instance
    .get(`/api/factoryGoods/queryGoodsDetails`, {
        params,
      })
      .then(res => res);
  },
  addCustomer: async function (data) {
    return await instance.post(`/api/acquire/addCustomer`, data).then(res => res);
  },
};
export default ajax;
