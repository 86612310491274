import { createSlice } from "@reduxjs/toolkit";

const modalFormSlice = createSlice({
  name: "modalForm",

  initialState: {
    visble: false,
    href:window.location.href
  },

  reducers: {
    setVisble: (state, action) => {
      state.visble = action.payload;
    },
    setHref:(state, action)=>{
        state.href = action.payload;
    }
  },
});

export const { setVisble ,setHref} = modalFormSlice.actions;


export default modalFormSlice.reducer;