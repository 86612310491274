import React from 'react';
import Head from '@/components/Head';
import HeadStation from '@/components/station/Head';
import Banner from '@/components/Banner';
import Foot from '@/components/Foot';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { setSessionStorage } from '../../assets/js/utils';
export default function Index() {
  const navigate = useNavigate();
  const params = useParams();
  const { category } = params;
  setSessionStorage('category', category);
  if (!['fashion', 'outdoor', 'toys','fabric','beauty'].includes(category)) {
    navigate('/');
    return;
  }
  return (
    <div>
      {window.location.hostname !== 'factory.163.com' ? <HeadStation category={category} /> : <Head />}
      <Banner category={category} />
      <Outlet />
      <Foot />
    </div>
  );
}
