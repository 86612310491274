import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { setVisble, setHref } from '@/store/slices/modalFormSlice';
import { Modal, Pagination, Empty, Input, message } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Banner from '@/components/Banner';
import Head from '@/components/Head';
import HeadStation from '@/components/station/Head';
import ajax from '@/request';
const category = window.location.hostname.slice(0, window.location.hostname.indexOf('.'));
export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const params = useParams();
  const { coId } = params;
  const products = data?.goodsList || [];
  const handlerClickProduct = (item, data, index) => {
    // if (categoryMap[category] === 'garments') {
    //   setIsModalOpen(true);
    //   setClickProductsIndex(index);
    // } else {
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    navigate(`/products/${category}/colectionDetails?id=${item.id}`);
    // }
  };
  const showModalForm = (e, item) => {
    e?.stopPropagation();
    dispatch(setVisble(true));
    //服装品类传productName
    if (window.location.pathname.includes('fashion')) {
      dispatch(setHref(item?.productName || window.location.host));
    } else {
      dispatch(setHref(window.location.href.replace(/list/, 'details') + '?id=' + item.id));
    }
  };
  useEffect(() => {
    ajax.getCollectionList({ suffix: coId }).then(res => {
      console.log(res);
      setData(res);
    });
  }, []);
  return (
    <div>
      {window.location.hostname !== 'factory.163.com' ? <HeadStation category={category} /> : <Head />}
      {/* <Banner
        bannerData={{
          title: data?.title,
          desc: data?.description,
          titleColor: '#000',
          descColor: '#666',
          bg: 'http://cowork-storage-public-cdn.lx.netease.com/common/2022/12/15/195fd2f076da42f8aaebdc84a70a2a7e.png',
        }}
      /> */}
      <Banner category={category} />
      <div className="g-products-list-wrap">
        <div className="g-products-content mx-auto mt-6">
          <div className="g-products-list  h-auto pb-14 flex flex-wrap  md:pb-1">
            {products?.map((item, index, data) => {
              return (
                <div
                  onClick={() => {
                    handlerClickProduct(item, data, index);
                  }}
                  key={index}
                  className="g-products-list-item"
                >
                  <LazyLoadImage
                    src={
                      item.masterImage
                        ? item.masterImage + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100'
                        : 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/03/02/1fbda02e016d4bb383dda7bded575a7c.png'
                    }
                    alt=""
                  />
                  <p className="g-products-list-item-title text-black-c md:hidden">
                    {item.productName.length > 51 ? item.productName.slice(0, 50) + '...' : item.productName}
                  </p>
                  {/* truncate */}
                  <p className="g-products-list-item-desc text-white md:hidden" style={{ opacity: item.productName.length > 51 ? '1' : '0' }}>
                    {item.productName}
                  </p>
                  <div className="send-us-btn g-btn g-btn-lg md:invisible" onClick={e => showModalForm(e, item)}>
                    Send us an inquiry
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
