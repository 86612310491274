import Base64 from 'base-64';
// import moment from 'moment';
var moment = require('moment');
const getScrollbarWidth = () => {
  const container = document.createElement('div');
  document.body.appendChild(container);
  container.style.overflow = 'scroll';
  const inner = document.createElement('div');
  container.appendChild(inner);
  const width = container.offsetWidth - inner.offsetWidth;
  document.body.removeChild(container);
  return width;
};
const setLocalStorage = (key, value) => {
  if (typeof value === 'object') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};
const setSessionStorage = (key, value) => {
  if (typeof value === 'object') {
    sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    sessionStorage.setItem(key, value);
  }
};

const getLocalStorage = key => {
  let val;
  try {
    val = JSON.parse(localStorage.getItem(key));
  } catch {
    val = localStorage.getItem(key);
  }
  return val;
};
const getSessionStorage = key => {
  let val;
  try {
    val = JSON.parse(sessionStorage.getItem(key));
  } catch {
    val = sessionStorage.getItem(key);
  }
  return val;
  // if (typeof val === 'object') {
  //   return JSON.parse(val);
  // } else {
  //   return val;
  // }
};
const checkAccessCode = str_b64 => {
  try {
    //不传参数从本地取code
    if (!str_b64) {
      str_b64 = getLocalStorage('ac');
    }
    // ES-20231028-abc ES-20231231-ChuyinEmailSigniture
    const str = Base64.decode(String(str_b64));
    //decode之后不符合格式  代表是假code
    if (str.indexOf('ES-') !== 0) {
      return 'invalid';
    }
    const date = str.slice(3, 11);
    console.log(11,moment())
    let isBefore = moment().isBefore(date); //当前时间isbefore验证码时间  代表有效
    if (isBefore) {
      return true;
    } else {
      return 'exceedTime';
    }
  } catch(e) {
    // console.log('code非法');
    console.error( e)
    return 'invalid';
  }
};

const checkEllipsis = elem => {
  function copyStyle(elem) {
    let computedStyle;
    const needStyle = ['fontSize', 'fontFamily', 'padding', 'border', 'fontWeight', 'color'];
    let result = [];
    if (typeof elem.currentStyle != 'undefined') {
      // 兼容IE
      computedStyle = elem.currentStyle;
    } else {
      computedStyle = window.getComputedStyle(elem, null);
    }
    needStyle.forEach(style => {
      result.push({
        name: style,
        value: computedStyle[style],
      });
    });
    return result;
  }
  let old, now;
  let newNode = elem.cloneNode(true);
  let result = copyStyle(elem);
  newNode.style.width = 'auto';
  newNode.style.visibility = 'visible';
  newNode.style.display = 'inline-block';
  result.forEach(item => {
    newNode.style[item.name] = item.value;
  });
  document.body.appendChild(newNode);
  old = elem.getBoundingClientRect().width;
  now = newNode.getBoundingClientRect().width;

  if (Math.abs(old - now) > 1) {
    // 溢出了
    newNode.remove();
    return true;
  }
  newNode.remove();
  return false;
};
const isM = () => {
  let userAgentInfo = navigator.userAgent;
  // eslint-disable-next-line no-array-constructor
  let Agents = new Array('Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod');
  let flag = false;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = true;
      break;
    }
  }
  return flag;
};
export { isM, getScrollbarWidth, setLocalStorage, getLocalStorage, setSessionStorage, getSessionStorage, checkAccessCode, checkEllipsis };
