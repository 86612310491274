import React, { useContext } from 'react';
import { Empty } from 'antd';
import { useNavigate, useParams, createSearchParams } from 'react-router-dom';
import arrow from '@/assets/img/category/arrow.png';
import arrowTrans from '@/assets/img/category/arrowTrans.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { productsContext } from '@/App';
import { setSessionStorage } from '../../../assets/js/utils';
export const categoryMap = {
  fashion: 'garments',
  outdoor: 'outdoorFurniture',
  toys: 'toysHobbies',
  beauty:'beauty',
  fabric:'fabric'
};
const bgcList = ['#5E79FF', '#171819', '#FFDAA5', '#5E79FF', '#171819', '#FFDAA5', '#5E79FF', '#171819', '#FFDAA5', '#5E79FF', '#171819', '#FFDAA5','#5E79FF', '#171819', '#FFDAA5', '#5E79FF', '#171819', '#FFDAA5', '#5E79FF', '#171819', '#FFDAA5', '#5E79FF', '#171819', '#FFDAA5'];
export default function Category() {
  const params = useParams();
  const navigate = useNavigate();
  const context = useContext(productsContext);
  const { category } = params;
  const data = context && context[categoryMap[category]];
  const goToList = (fatherName, categoryName, index, childIndex) => {
    const p = { fatherName, categoryName, index, childIndex };
    setSessionStorage('list', { categoryName, index, childIndex });
    navigate({
      pathname: `/products/${category}/list`,
      search: `?${createSearchParams(p)}`,
    });
  };
  if (!data)
    return (
    //   <Empty style={{ color: '#656565', padding: '100px 0' }} description="More coming soon" />
       <Empty
          style={{color:'#656565',padding:'100px 0'}}
          image='https://cowork-storage-public-cdn.lx.netease.com/common/2022/11/10/718ec41aae954197a0e469eb12a8ca2d.gif'
          imageStyle={{display:'flex',justifyContent: 'center'}}
          description="More coming soon" />
    );
  return (
    <div className="g-category-list-wrap">
      {
        data?.childCategory.map((child, i) => (
          <div key={i} className="g-category-list  g-content mx-auto flex  flex-wrap md:justify-between md:px-6">
            <div className="g-category-list-item mb-6  g-category-list-first " style={{ backgroundColor: bgcList[i] }}>
              <span className="font-bold">{child.categoryName}</span>
              <LazyLoadImage src={arrowTrans} alt="" />
            </div>
            {child.childCategory.map((item, index) => (
              <div
                onClick={() => goToList(child.categoryName, item.categoryName, i, index)}
                className={['g-category-list-item mb-6' /* index === 1 ? 'blur' : '' */].join(' ')}
                key={index}
               >
                    <LazyLoadImage src={item.image + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100'} alt="" />
                <span className="g-category-list-item-title text-white text-lg md:text-sm"> {item.categoryName}</span>
                <div className="g-category-list-item-mask h-full w-full md:hidden">
                  <span className="g-category-list-item-mask-title text-white text-lg font-bold"> {item.categoryName}</span>
                  {/* bg-black-b */}
                  <div className="g-category-list-item-blue  h-full">
                    <LazyLoadImage src={arrow} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      }
    </div>
  );
}
