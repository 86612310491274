import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Controller } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
const imgAlt = 'NetEase';
export default function HomePcSwiper({list}) {
  const left = {
    left: '-40px',
  };
  const right = {
    right: '-20px',
  };
  return (
    <div className='pc' style={{ height: '492px' }}>
      <Swiper
        key="pc"
        className='pc'
        modules={[Navigation, Controller]}
        spaceBetween={10}
        slideToClickedSlide={true}
        centeredSlides={true}
        slidesPerView={3}
        loop
        initialSlide={1}
        onSlideChange={() => console.log('slide change')}
        navigation={{
          nextEl: '.pc .swiper-button-next',
          prevEl: '.pc .swiper-button-prev',
        }}
      >
        {list.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              {/* {boxStyle} */}
              <div className="g-home-weHave-box">
                <img src={item?.images} alt="imgAlt" className="mx-auto" style={{ width: '370px', height: '330px' }} />
                <div
                  style={{
                    border: '1px solid #eee',
                    boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    fontFamily: 'Arial',
                    color: '#000000',
                    padding: '28px',
                    height: '198px',
                  }}
                >
                  <div className="mt-5" style={{ fontWeight: 700, fontSize: '24px', marginBottom: '10px' }}>
                    {item?.title}
                  </div>
                  <div style={{ fontSize: '16px' }}>{item?.text}</div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={'pc swiper-button-prev'} style={left} />
      <div className={'pc swiper-button-next'} style={right} />
    </div>
  );
}
