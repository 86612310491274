import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Modal, Pagination, Empty, Input, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { productsContext } from '@/App';
import ModalForm from '@/components/ModalForm';
import BreadMenu from '@/components/BreadMenu';
import { setLocalStorage, checkAccessCode } from '@/assets/js/utils';
import ajax from '@/request';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setVisble, setHref } from '@/store/slices/modalFormSlice';
import { Icon } from '@nutui/nutui-react';
import { useSwipeable } from 'react-swipeable';
import {categoryMap} from './Category'
// const categoryMap = {
//   fashion: 'garments',
//   outdoor: 'outdoorFurniture',
//   toys: 'toysHobbies',
//   beauty:'beauty',
//   fabric:'fabric'
// };
const useAccessCode = () => {
  const checkAccessCodeResult = useMemo(checkAccessCode, []);
  return checkAccessCodeResult;
};
const ModalDetail = React.memo(props => {
  const checkAccessCodeResult = useAccessCode();
  const dispatch = useDispatch();
  const { visble, onCancel, index, data, total, onChangePage, currentPage } = props;
  const [currentIndex, setCurrentIndex] = useState(index);
  const { masterImage, productName, desc } = data[currentIndex] || {};

  if (data.length === 0 || !masterImage) {
    // return '';
  }
  const handlerSwitchImage = n => {
    if (data.length === 0) {
      return;
    }
    //临界点切换上一页
    if (n === -1 && currentIndex === 0) {
      if (currentPage === 1) {
        // message.info('This is first');
        return;
      }
      if (checkAccessCodeResult === true) {
        onChangePage(currentPage + n).then(red => {
          //   console.log(121, red);
          setCurrentIndex(8);
        });
        return;
      }
    }

    //临界点切换下一页
    if (n === 1 && currentIndex === data.length - 1) {
      if (checkAccessCodeResult === true) {
        if (Math.ceil(total / 9) === currentPage) {
          //   message.info('This is last');
          return;
        }
        onChangePage(currentPage + n).then(r => {
          setCurrentIndex(0);
        });
        return;
      } else {
        n === 1 && showModalFormWithModalDetail();
        return;
      }
    }
    setCurrentIndex(currentIndex + n);
  };
  /* 移动端滑动事件 */
  const handlers = useSwipeable({
    onSwipedLeft: eventData => {
      handlerSwitchImage(1);
    },
    onSwipedRight: eventData => {
      handlerSwitchImage(-1);
    },
  });
  const showModalFormWithModalDetail = (e, productName) => {
    e?.stopPropagation();
    dispatch(setVisble(true));
    dispatch(setHref(productName || window.location.host));
  };

  return (
    <Modal className="g-products-detail-modal" width={600} open={visble} onCancel={onCancel} footer="">
      <div className="flex flex-col">
        <div className="xxll0 flex justify-between items-center md:mt-10">
          <LeftOutlined
            className="md:translate-x-4"
            style={{ color: currentPage === 1 && currentIndex === 0 ? '#eee' : '' }}
            onClick={() => handlerSwitchImage(-1)}
          />
          <div style={{ width: '20vw', minWidth: '200px', height: '20vw', minHeight: '200px' }}>
            <LazyLoadImage {...handlers} src={masterImage + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100'} alt="" />
          </div>
          <RightOutlined
            className="md:-translate-x-4"
            style={{ color: currentIndex === data.length - 1 && Math.ceil(total / 9) === currentPage ? '#eee' : '' }}
            onClick={() => handlerSwitchImage(1)}
          />
        </div>
        <p className="ml-14 mt-2 md:ml-0 md:text-center">{productName}</p>
        <p>{desc}</p>
        <div className="g-btn g-btn-lg  mt-2 mx-left font-bold ml-14 md:mb-10 md:mx-auto" onClick={e => showModalFormWithModalDetail(e, productName)}>
          Send us an inquiry
        </div>
      </div>
    </Modal>
  );
});
export default function ProductList() {
  const context = useContext(productsContext);
  const checkAccessCodeResult = useAccessCode();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const pageNum = searchParams.get('page') || 1;
  const fatherName = searchParams.get('fatherName') || '';
  const categoryName = searchParams.get('categoryName');
  const index = searchParams.get('index');
  const childIndex = searchParams.get('childIndex');
  const { category } = params;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickProductsIndex, setClickProductsIndex] = useState({});
  const [products, setProducts] = useState({ data: null });
  const [currentPage, setCurrentPage] = useState(pageNum / 1);
  const [clickCategoryName, setClickCategoryName] = useState(categoryName);
  const [codeValue, setCodeValue] = useState('');
  const [clickIndex, setClickIndex] = useState({ fatherName, index, childIndex, categoryName });
  const [allowAccess, setAllowAccess] = useState(checkAccessCodeResult);
  const data = context && context[categoryMap[category]];
  const onChangePage = page => {
    searchParams.set('page', page);
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    return getProducts(fatherName, clickCategoryName, page).then(() => {
      setCurrentPage(o => {
        return page;
      });
    });
  };

  const handlerClickCategoryName = (fatherName, categoryName, index, childIndex) => {
    setCurrentPage(1);
    setClickCategoryName(categoryName);
    setClickIndex({ index, childIndex, categoryName, fatherName });
    searchParams.set('fatherName', fatherName);
    searchParams.set('categoryName', categoryName);
    searchParams.set('index', index);
    searchParams.set('childIndex', childIndex);
    searchParams.set('page', 1);
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    getProducts(fatherName, categoryName, 1);
  };
  const handlerClickProduct = (item, data, index) => {
    if (categoryMap[category] === 'garments') {
      setIsModalOpen(true);
      setClickProductsIndex(index);
    } else {
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
      navigate(`/products/${category}/details${window.location.search}&id=${item.id}`);
    }
  };
  const changeCodeInput = e => {
    setCodeValue(e.target.value);
  };
  const handlerSubmitCode = () => {
    //检验code合法性
    const isValid = checkAccessCode(codeValue);
    if (isValid === 'invalid') {
      message.error('Access code invalid. Please request a valid code.');
    } else if (isValid === 'exceedTime') {
      message.error('Access code expired. Please request a new code.');
    } else if (isValid) {
      message.success('code is valid');
      setAllowAccess(true);
      setLocalStorage('ac', codeValue);
    } else {
      console.log('校验code失败');
    }
  };
  const isShowClear = useMemo(() => {
    return currentPage === 1 || allowAccess === true;
  }, [currentPage, allowAccess]);
  const showModalForm = (e, item) => {
    e?.stopPropagation();
    dispatch(setVisble(true));
    //服装品类传productName
    if (location.pathname.includes('fashion')) {
      dispatch(setHref(item?.productName || window.location.host));
    } else {
      dispatch(setHref(window.location.href.replace(/list/, 'details') + '?id=' + item.id));
    }
  };
  const getProducts = async (fatherName, categoryName, page) => {
    const res = await ajax.getProducts(categoryMap[category], {
      tier1Category: fatherName,
      tier2Category: categoryName || clickCategoryName,
      pageIndex: page || currentPage,
      pageSize: '9',
    });
    setProducts(res);
  };
  const goBack = () => {
    navigate(`/products/${category}`);
  };
  useEffect(() => {
    getProducts(fatherName, clickIndex.categoryName, currentPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (data === null || products?.data === null) {
    return (
      <div className="flex justify-center items-center">
        <Empty
          style={{ color: '#656565', padding: '100px 0' }}
          image="https://cowork-storage-public-cdn.lx.netease.com/common/2022/11/10/718ec41aae954197a0e469eb12a8ca2d.gif"
          imageStyle={{ display: 'flex', justifyContent: 'center' }}
          description="More coming soon"
        />
      </div>
    );
  }
  return (
    <div>
      {/* <ModalForm /> */} 
      {isModalOpen && (
        <ModalDetail
          visble={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          data={products.data}
          total={products.total}
          index={clickProductsIndex}
          onChangePage={page => onChangePage(page)}
          currentPage={currentPage}
        />
      )}
      <div className="md:hidden">
        <BreadMenu menu={[clickIndex.fatherName, clickIndex.categoryName]} />
      </div>
      <div className="hidden md:block p-4 ">
        <Icon name="left" onClick={goBack} />
      </div>
      <div className="g-products-list-wrap flex g-content mx-auto justify-between md:px-4">
        <div className="g-products-tree pb-8 g-hidden-scrollbar md:hidden">
          <h1 className="font-bold text-lg text-black-c pb-3">Categories</h1>
          {data &&
            data.childCategory.map((item, index, arr) => {
              if (item.childCategory) {
                return (
                  <div key={item.categoryName + index}>
                    <h2 className="font-bold text-black-c text-base mt-7 ">{item.categoryName}</h2>
                    {item.childCategory.map((childItem, childIndex, arr) => (
                      <p
                        onClick={() => handlerClickCategoryName(item.categoryName, childItem.categoryName, index, childIndex)}
                        className="text-sm text-black mt-3 hover:cursor-pointer"
                        key={childItem.categoryName + index}
                        // eslint-disable-next-line eqeqeq
                        style={{ color: index == clickIndex.index && childIndex == clickIndex.childIndex ? '#6575F8' : '' }}>
                        {childItem.categoryName}&nbsp;({childItem.total})
                      </p>
                    ))}
                  </div>
                );
              } else {
                return (
                  <p
                    onClick={() => handlerClickCategoryName(item.categoryName, index)}
                    className="text-sm text-black mt-3 hover:cursor-pointer"
                    key={item.categoryName + index}
                    style={{ color: index === clickIndex.index ? '#6575F8' : '' }}>
                    {item.categoryName}&nbsp;({item.total})
                  </p>
                );
              }
            })}
        </div>
        <div className="g-products-content">
          <div className="g-products-list  h-auto pb-14 flex flex-wrap  md:pb-1">
            {products?.data?.map((item, index, data) => {
              return isShowClear ? (
                <div
                  onClick={() => {
                    handlerClickProduct(item, data, index);
                  }}
                  key={index}
                  className="g-products-list-item">
                  <LazyLoadImage src={item.masterImage?item.masterImage + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100':'https://cowork-storage-public-cdn.lx.netease.com/common/2023/03/02/1fbda02e016d4bb383dda7bded575a7c.png'} alt="" />
                  <p className="g-products-list-item-title text-black-c md:hidden">
                    {item.productName.length > 51 ? item.productName.slice(0, 50) + '...' : item.productName}
                  </p>
                  {/* truncate */}
                  <p className="g-products-list-item-desc text-white md:hidden" style={{ opacity: item.productName.length > 51 ? '1' : '0' }}>
                    {item.productName}
                  </p>
                  <div className="send-us-btn g-btn g-btn-lg md:invisible" onClick={e => showModalForm(e, item)}>
                    Send us an inquiry
                  </div>
                </div>
              ) : (
                <div key={index} className="g-products-list-item item-noAllow">
                  <LazyLoadImage src={item.masterImage + '?imageView&thumbnail=1100x1100&enlarge=1&pad=1100_1100'} alt="" className="blur-md" />
                  <p className="g-products-list-item-title text-black-c md:hidden">
                    {' '}
                    {item.productName.length > 25 ? item.productName.slice(0, 24) + '...' : item.productName}
                  </p>
                  <p className="g-products-list-item-desc text-white" style={{ opacity: item.productName.length > 25 ? '1' : '0' }}>
                    {item.productName}
                  </p>
                  <div className="g-products-list-item-noAllow-from flex flex-col items-center md:hidden">
                    <Input
                      placeholder="Enter access code"
                      className="noAllow-from-input"
                      onPressEnter={handlerSubmitCode}
                      onChange={changeCodeInput}
                    />
                    <div onClick={handlerSubmitCode} className="g-btn sumbit-btn mt-10">
                      Sumbit
                    </div>
                    <div onClick={e => showModalForm(e, item)} className="g-btn request-btn mt-4">
                      Request a Code
                    </div>
                  </div>
                </div>
              );
            })}
            {!isShowClear && (
              <div className="g-products-list-item-noAllow-from flex flex-col items-center invisible md:visible">
                <Input placeholder="Enter access code" className="noAllow-from-input" onPressEnter={handlerSubmitCode} onChange={changeCodeInput} />
                <div onClick={handlerSubmitCode} className="g-btn sumbit-btn mt-10">
                  Sumbit
                </div>
                <div onClick={e => showModalForm(e, '')} className="g-btn request-btn mt-4">
                  Request a Code
                </div>
              </div>
            )}
          </div>
          {products?.data?.length ? (
            <Pagination
              className="g-products-list-pagination float-right"
              hideOnSinglePage={true}
              current={currentPage}
              pageSize={9}
              onChange={onChangePage}
              total={products.total}
              pageSizeOptions={[]}
            />
          ) : (
            <Empty
              style={{ color: '#656565', padding: '100px 0' }}
              // image='https://cowork-storage-public-cdn.lx.netease.com/common/2022/11/10/718ec41aae954197a0e469eb12a8ca2d.gif'
              imageStyle={{ display: 'flex', justifyContent: 'center' }}
              description="More coming soon"
            />
          )}
        </div>
      </div>
    </div>
  );
}
