import React from 'react';
import Home from '../pages/Home';
import About from '../pages/indep/About';
import Collection from '../pages/collection/Index';
import Products from '../pages/products/Index';
import Details from '../pages/products/child/Details';
import ColectionDetails from '../pages/products/child/colectionDetails';
import Productlist from '../pages/products/child/Productlist';
import Category from '../pages/products/child/Category';
import NotFound from '../pages/NotFound';
// import Indep from '../pages/indep/Index';
let hostName = 'factory.163.com';
if (window?.location?.hostname && window?.location?.hostname.includes('factory.163.com')) {
  hostName = window?.location?.hostname;
}
/* 根据host区分主站和独立站路由 */
let router = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/products/:category',
    element: <Products />,
    children: [
      { path: '', element: <Category /> },
      { path: 'list', element: <Productlist /> },
      { path: 'details', element: <Details /> },
      { path: 'colectionDetails', element: <ColectionDetails /> },
    ],
  },
  {
    path: '/collection/:coId',
    element: <Collection />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
if (hostName !== 'factory.163.com') {
  router.unshift({
    path: '/',
    element: <Home />,
  });
} else {
    // navigate('/');
//   return <Navigate to="factory.163.com" />;
    router.unshift({
      path: '/',
      element: <Home />,
    });
}

// console.log('router',router)
export default router;
