import React from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Form, Input, Button, Message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setVisble } from '../store/slices/modalFormSlice';
// import moment from 'moment';
import ajax from '@/request';
import { getLocalStorage } from '../assets/js/utils';
var moment = require('moment');  
const hostName=window.location.hostname
const mailInfo={
    'main':{
        title:'Contact Us',
        saleMail:'sale@factory.163.com',
        supplyMail:'supply@factory.163.com'
    },
    'fashion':{
        title:"Join us in creating a sustainable future of fashion",
        saleMail:'fashion@factory.163.com',
        supplyMail:'fashion@factory.163.com'
    },
    'beauty':{
        title:"Join us in creating a better future ",
        saleMail:'beauty@factory.163.com',
        supplyMail:'beauty@factory.163.com'
    },
    'fabric':{
        title:"Join us in creating a sustainable future of fabric",
        saleMail:'fabric@factory.163.com',
        supplyMail:'fabric@factory.163.com'
    },
    'toys':{
        title:"Join us in creating a better future",
        saleMail:'toys@factory.163.com',
        supplyMail:'toys@factory.163.com'
    },
    'outdoor':{
        title:"Join us in creating a better future",
        saleMail:'outdoor@factory.163.com',
        supplyMail:'outdoor@factory.163.com'
    }
  }
function RegisterForm(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const href = useSelector(state => state.modalForm.href);
  const  category  = params.category||props.category;
  const categ=props.category||'main'// 用来区分邮箱信息
  const category2Ch = key => {
    const map = {
      fashion: '可持续时尚',
      outdoor: '户外产品',
      toys: '潮玩',
    };
    return map[key];
  };
  const onFinish = values => {  
    const data = {
      ...values,
      customerIp: window.returnCitySN ? window.returnCitySN['cip'] : '',
      accessCode: getLocalStorage('ac'),
      originSku: href,
      contactDate: moment().format('YYYY-MM-DD'),
      serviceLine: category2Ch(category),
    };
    ajax.addCustomer(data).then(res => {
    //   console.log(res);
      if (res.code === 200) {
        dispatch(setVisble(false));
        Message.success('Success');
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <p className="text-xxl1 text-white w-10/12 mx-auto font-bold  text-center md:text-xl md:mt-4">{mailInfo[categ]?.title}</p>
      <p className="modal-subTitle text-base mt-3 text-white text-center md:hidden">Don't be afraid to reach out. You + us = awesome.</p>
      <Form
        className="g-contactUs-register-from"
        name="basic"
        labelAlign="right"
        labelWrap={true}
        labelCol={{ span: 3 }}
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          label="Name"
          name="customerName"
          rules={[
            { required: true, message: 'Please input your name!' },
            { max: 200, message: 'Character is too long' },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { max: 100, message: 'Character is too long' },
            {
              pattern: /^[a-zA-Z0-9_-]+(\.?[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              message: 'invalid E-mail',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Message" name="customerInfo" rules={[{ max: 1000, message: 'Character is too long' }]}>
          <Input.TextArea style={{ height: '100px' }} />
        </Form.Item>
        <Form.Item>
          <Button shape="circle" type="primary" htmlType="submit" className="g-btn g-btn-l mt-14 modal-submit text-base font-bold mx-auto md:translate-y-3">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <p className="text-center text-base text-white mt-20 md:text-base">
        Or{' '}
        <a
          className="text-blue hover:text-blue"
          href={`mailto:${mailInfo[categ]?.salelyMail}?subject=Schedule a Meeting with me!&body=I want to know more about ${href}`}>
          send an e-mail
        </a>{' '}
        directly to {mailInfo[categ]?.supplyMail}
      </p>
    </>
  );
}
function ModalForm(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const visble = useSelector(state => state.modalForm.visble);
  const href = useSelector(state => state.modalForm.href);
  const  category  = params.category||props.category;
  const categ=props.category||'main'// 用来区分邮箱信息
  if (!visble) return '';
  //   console.log('无效code------>', Base64.encode('EfdS-20221028-abc'));
  //   console.log('过期code------>', Base64.encode('ES-20221028-abc'));
  //   console.log('有效code------>', Base64.encode('ES-20231128-abc'));
  //   console.log(Base64.decode('RVMtMjAyMzExMjgtYWJj'));
  const category2Ch = key => {
    const map = {
      fashion: '可持续时尚',
      outdoor: '户外产品',
      toys: '潮玩',
    };
    return map[key];
  };
  const onFinish = values => {
    const data = {
      ...values,
      customerIp: window.returnCitySN ? window.returnCitySN['cip'] : '',
      accessCode: getLocalStorage('ac'),
      originSku: href,
      contactDate: moment().format('YYYY-MM-DD'),
      serviceLine: category2Ch(category),
    };
    ajax.addCustomer(data).then(res => {
    //   console.log(res);
      if (res.code === 200) {
        dispatch(setVisble(false));
        Message.success('Success');
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Modal maskClosable={true} className="g-contactUs-modal" width={744} open={visble} onCancel={() => dispatch(setVisble(false))} footer="">
      <p className="text-xxl text-black-c font-bold text-center md:text-xl">{mailInfo[categ]?.title}</p>
      <p className="modal-subTitle text-base text-black-e text-center md:hidden">Don't be afraid to reach out. You + us = awesome.</p>
      <Form
        className="g-contactUs-modal-from"
        name="basic"
        labelAlign="right"
        labelWrap={true}
        labelCol={{ span: 3 }}
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          label="Name"
          name="customerName"
          rules={[
            { required: true, message: 'Please input your name!' },
            { max: 200, message: 'Character is too long' },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { max: 100, message: 'Character is too long' },
            {
              pattern: /^[a-zA-Z0-9_-]+(\.?[a-zA-Z0-9_-]+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              message: 'invalid E-mail',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Message" name="customerInfo" rules={[{ max: 1000, message: 'Character is too long' }]}>
          <Input.TextArea style={{ height: '100px' }} />
        </Form.Item>
        {/* <Form.Item label="Company Website" name="customerWebsite" rules={[{ max: 200, message: 'Character is too long' }]}>
          <Input />
        </Form.Item> */}
        {/* <Form.Item label="Company Turnover Size" name="yearSalesVolume">
          <Select onChange={handleChangeTurnoverSize}>
            <Option value="<250k">&lt; $250k</Option>
            <Option value="$250k - $500k">$250k - $500k</Option>
            <Option value="$500k - $1Mn">$500k - $1Mn</Option>
            <Option value="$1Mn - $5Mn">$1Mn - $5Mn</Option>
            <Option value="$5Mn - $10Mn">$5Mn - $10Mn</Option>
            <Option value=">$10Mn">&gt; $10Mn</Option>
          </Select>
        </Form.Item> */}
        <Form.Item>
          <Button shape="circle" type="primary" htmlType="submit" className="g-btn g-btn-l mt-14 modal-submit text-base font-bold mx-auto md:translate-y-3">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <p className="text-center text-lg text-black-c mt-20 md:text-base">
        Or{' '}
        <a
          className="text-blue hover:text-blue"
        //   hostName==='factory.163.com'?'supply@factory.163.com':'fashion@factory.163.com'
          href={`mailto:${mailInfo[categ]?.saleMail}?subject=Schedule a Meeting with me!&body=I want to know more about ${href}`}>
          send an e-mail
        </a>{' '}
        directly to {mailInfo[categ]?.supplyMail}{' '}
      </p>
    </Modal>
  );
}
export default React.memo(ModalForm);
export { RegisterForm };
