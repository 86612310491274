import React from 'react';
import { useLocation } from 'react-router-dom';
import beautyBg from '../assets/img/category/beautyBanner.png';
import fabricBg from '../assets/img/category/fabricBanner.png';
// import fashionBg from '../assets/img/category/fashion-banner.png';
// import outdoorBg from '../assets/img/category/outdoor-banner.png';
// import toysBg from '../assets/img/category/toys-banner.png';
const propsMap = {
  fashion: {
    title: 'Sustainable Fashion',
    desc: 'We provide sustainable fashion products to create a better <br/> living environment for our customers',
    titleColor: '#000',
    descColor: '#666',
    bg: 'http://cowork-storage-public-cdn.lx.netease.com/common/2022/12/15/195fd2f076da42f8aaebdc84a70a2a7e.png',
  },
  outdoor: {
    title: 'Outdoor Living',
    desc: 'The use of new composite materials, so that you enjoy a<br/> comfortable natural life',
    descM: 'The use of new composite materials, so that you enjoy a comfortable natural life',
    titleColor: '#fff',
    descColor: '#fff',
    bg: 'http://cowork-storage-public-cdn.lx.netease.com/common/2022/12/15/fd0501f298ed4527a28059b7c0732748.png',
  },
  toys: {
    title: 'Toys & Hobbies',
    desc: 'Find full range of toy products featuring<br/> your customers right here at EaseSupply now!',
    titleColor: '#fff',
    descColor: '#fff',
    bg: 'https://cowork-storage-public-cdn.lx.netease.com/common/2023/02/03/30d344b47c084b7dab5c861ed9ba6dc9.png',
  },
  beauty: {
    title: 'Beauty',
    desc: 'We interprets feminine beauty, creating lines of products<br/> for cosmetics brands in every corner of the globe',
    titleColor: '#333',
    descColor: '#333',
    bg: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/29/426c98464c7447bfadb1d09da18d1c8a.png'
  },
  fabric: {
    title: 'Fabric',
    desc: 'We provide sustainable fabric products to create <br/> a better living environment for our customers',
    titleColor: '#333',
    descColor: '#333',
    bg: 'https://cowork-storage-public-cdn.lx.netease.com/common/2022/12/29/3eb10d805da04bd7a955aecd5e36d9a2.png'
  },
};
export default function Banner(props) {
  const location=useLocation()
  if(location.pathname.includes('details')){
    return ''
  }
  const { category, bannerData} = props;
  const p = propsMap[category]||bannerData;
  return (
    <div className="g-category-banner w-full bg-cover md:hidden" style={{ backgroundImage: `url(${p.bg})` }}>
      <div className="g-content mx-auto md:p-6">
        <p className="font-bold text-xl text-black" style={{ color: p.titleColor }}>
          {p.title}
        </p>
        <p className="g-category-banner-desc mt-3 text-base md:hidden" style={{ color: p.descColor }} dangerouslySetInnerHTML={{ __html: p.desc }}></p>
        <p className="g-category-banner-desc mt-3 text-base hidden md:block " style={{ color: p.descColor }} >{p.descM}</p>
      </div>
    </div>
  );
}
