import axios from 'axios'
// axios.defaults.withCredentials = true
// axios.defaults.crossDomain=true
//     axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
const URL={
    "test":'https://factory-scm.lx.netease.com',
    "development":'http://factory-scm.cowork.netease.com',
    "production":'https://factory.163.com/factoryapp'
}
console.log(process.env.NODE_ENV)
const baseUrl = URL[process.env.NODE_ENV]
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 12 * 1000
})
//请求拦截

instance.interceptors.request.use(
  config => {
    return config
  },
  error => Promise.error(error))
// 响应拦截器
instance.interceptors.response.use(
  res => {
    if (res.status === 200) {
        // console.log('RES.DATA===>',res.data)
      return Promise.resolve(res.data)
    } else {
      console.error('网络异常')
      Promise.reject(res)
    }
  },
  error => {
    console.error('网络异常')
    const { response } = error
    if (response) {
      return Promise.reject(response)
    }
  })
export { instance }
